import React, { useState } from "react";
import { serviceProvider as API } from "../../../API/api";

// Components
import { Alert, Button, Modal, Label, Select, TextInput } from "flowbite-react";

// Icons
import { InfoIcon } from "../../../images/icons/Icons";
import { sortByName } from "../../../lib/genericUtils";


function ScoresModal({ action, openModal, referee, score, seasons, setOpenModal, setScore, setScoreAlert, setReferee, setReferees }) {
    const [alert, setAlert] = useState({ type: '', label: '' });

    const handleInput = e => {
        const { id, value } = e.currentTarget;
        setScore(prevState => ({
            ...prevState,
            [id]: value
        }))
    }

    const handleSeason = e => {
        const { value } = e.currentTarget;
        setScore(prevState => (
            {
                ...prevState,
                season: seasons.find(s => s._id.toString() === value) || { _id: '' }
            }
        ))
    }

    const handleSubmit = e => {
        e.preventDefault();
        if (!score.season?._id)
            return setAlert({ type: 'warning', label: 'Non hai impostato una stagione' })
        else setAlert({ type: '', label: '' })

        const updUser = {
            ...referee,
            scores: action === 'delete'
                // DELETE:
                ? [...referee.scores.filter(s => s._id.toString() !== score._id.toString())]
                : action === 'edit'
                    // EDIT:
                    ? [...referee.scores.filter(s => s._id.toString() !== score._id.toString()), {...score, updated_at: new Date() }]
                    // NEW:
                    : [...referee.scores, {...score, inserted_at: new Date(), updated_at: new Date()}]
        }
        API.update(`users/${referee._id}`, JSON.stringify(updUser), true)
            .then(res => {
                if (res.success) {
                    setReferee(res.user);
                    setReferees(prevState => sortByName([
                        ...prevState.filter(r => r._id.toString() !== referee._id.toString()),
                        res.user
                    ], 'lastname'));
                    setScoreAlert({
                        type: 'success',
                        label: `Valutazione ${action === 'delete' ? 'eliminata' : action === 'edit' ? 'modificata' : 'inserita'}`
                    });
                    setOpenModal(false);
                } else {
                    setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto, riprova più tardi' });
                }
            })
            .catch(err => {
                console.error(err);
                setAlert({ type: 'failure', label: 'Qualcosa è andato storto, si prega di riprovare' });
            });
        /* switch (action) {
            case 'delete':

                break;

            case 'edit':
                break;

            default:
                API.update(`users/${referee._id}`, JSON.stringify(updUser), true)
                    .then(res => {
                        if (res.success) {
                            setReferee(res.user);
                            setReferees(prevState => sortByName([
                                ...prevState.filter(r => r._id.toString() !== referee._id.toString()),
                                res.user
                            ], 'lastname'));
                            setScoreAlert({ type: 'success', label: 'Valutazione inserita' });
                            setOpenModal(false);
                        } else {
                            setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto, riprova più tardi' });
                        }
                    })
                    .catch(err => {
                        console.error(err);
                        setAlert({ type: 'failure', label: 'Qualcosa è andato storto, si prega di riprovare' });
                    });
        } */
    }

    return (
        <Modal
            show={openModal}
            size='lg'
            popup={true}
            onClose={() => setOpenModal(false)}
        >
            <Modal.Header>
                <div className="ml-4 my-2">
                    {action === 'new' ? 'Nuova' : action === 'edit' ? 'Modifica' : 'Elimina'} Valutazione
                </div>
            </Modal.Header>
            <Modal.Body>
                {alert.type &&
                    <Alert
                        color={alert.type}
                        icon={InfoIcon}
                    >
                        <span>
                            <span className="font-medium">
                                Attenzione!
                            </span>
                            {' '}
                            {alert.label}
                        </span>
                    </Alert>
                }
                <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
                    <form
                        className="flex flex-col gap-4"
                        onSubmit={handleSubmit}
                    >
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="season"
                                    value="Stagione"
                                />
                            </div>
                            <Select
                                id="season"
                                value={score.season?._id}
                                onChange={handleSeason}
                                disabled={action === 'delete' ? true : false}
                            >
                                <option value=''>
                                    -
                                </option>
                                {seasons.map(s =>
                                    <option key={s._id} value={s._id}>
                                        {s.name}
                                    </option>
                                )}
                            </Select>
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="context"
                                    value="Ambito"
                                />
                            </div>
                            <Select
                                id="context"
                                required={true}
                                value={score.context}
                                onChange={handleInput}
                                disabled={action === 'delete' ? true : false}
                            >
                                <option value="Tecnico">
                                    Tecnico
                                </option>
                                <option value="Comportamentale">
                                    Comportamentale
                                </option>
                                <option value="Commissione">
                                    Commissione
                                </option>
                            </Select>
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="points"
                                    value="Bonus/Malus"
                                />
                            </div>
                            <TextInput
                                id="points"
                                type="number"
                                required={true}
                                value={score.points}
                                onChange={handleInput}
                                step="0.1"
                                disabled={action === 'delete' ? true : false}
                            />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="reason"
                                    value="Motivazione"
                                />
                            </div>
                            <TextInput
                                id="reason"
                                type="text"
                                value={score.reason}
                                onChange={handleInput}
                                disabled={action === 'delete' ? true : false}
                            />
                        </div>
                        {action === 'delete' &&
                            <Alert color="warning">
                                Vuoi eliminare questa valutazione?
                            </Alert>
                        }
                        {action !== 'new' &&
                            <Button
                                color={action === 'delete' ? "failure" : "warning"}
                                type="submit">
                                {action === 'delete' ? "Elimina" : "Modifica"}
                            </Button>
                        }
                        {action === 'new' &&
                            <Button
                                type="submit"
                            >
                                Invia
                            </Button>
                        }
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ScoresModal;