import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { serviceProvider as API } from "../../API/api";
import { formatDateTime, sortByDate } from "../../lib/genericUtils";

// Components
import { Alert, Button, Table } from "flowbite-react";
import LoadingSpinner from "../LoadingSpinner";
import ReportPreview from "./reports/ReportPreview";

// Icons
import { InfoIcon } from "../../images/icons/Icons";

// Series
import series from "../../lib/series";


const ReportRow = ({ report, setAction, setReport, setShowReferee }) => {
    const { _id: userId } = useSelector(state => state.user);

    const handlePreview = () => {
        setReport(report);
        setShowReferee(
            report.general.first_ref._id.toString() === userId.toString()
                ? '1st'
                : '2nd'
        );
        setAction('preview');
    }

    return (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell>
                {formatDateTime(report.general.date)}
            </Table.Cell>
            <Table.Cell>
                {series[report.general.series]}
            </Table.Cell>
            <Table.Cell>
                {report.general.teams}
            </Table.Cell>
            <Table.Cell>
                {`${report.general.first_ref.lastname} ${report.general.first_ref.firstname[0]}.`}
                {report.general.second_ref && ` - ${report.general.second_ref.lastname} ${report.general.second_ref.firstname[0]}.`}
            </Table.Cell>
            <Table.Cell>
                <Button
                    size="xs"
                    onClick={handlePreview}
                >
                    <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        >
                        </path>
                    </svg>
                </Button>
            </Table.Cell>
        </Table.Row>
    )
}

function RefereeHome() {
    const { _id: userId } = useSelector(state => state.user);
    const season = useSelector(state => state.season);
    const [action, setAction] = useState('table');
    const [alert, setAlert] = useState({ type: '', label: '' });
    const [loading, setLoading] = useState(true);
    const [report, setReport] = useState({});
    const [reports, setReports] = useState([]);
    const [showReferee, setShowReferee] = useState('1st');

    const goBack = () => {
        setAction('table');
        setReport({});
        setShowReferee('1st');
    }

    useEffect(() => {
        if (userId && season._id) {
            API.get(`reports?referee=${userId}&startDate=${season.startDate}&endDate=${season.endDate}`, true)
                .then(res => {
                    if (res.success) {
                        setReports(sortByDate(res.reports, 'general.date'));
                    }
                    setLoading(false);
                })
                .catch(err => {
                    console.error(err);
                    setAlert({ type: 'failure', label: 'Qualcosa è andato storto, riprova più tardi' });
                })
        }
    }, [userId, season])

    return (
        <main className="container mx-auto min-h-screen px-5 py-20 lg:px-10 md:py-48">
            {
                action === 'table' &&
                <div className="flex flex-col">
                    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                            {alert.type &&
                                <Alert
                                    className="mb-2"
                                    color={alert.type}
                                    icon={InfoIcon}
                                >
                                    <span>
                                        {alert.label}
                                    </span>
                                </Alert>
                            }
                            <div className="overflow-hidden sm:rounded-lg shadow-md mb-2">
                                <Table hoverable={true}>
                                    <Table.Head>
                                        <Table.HeadCell>
                                            Data
                                        </Table.HeadCell>
                                        <Table.HeadCell>
                                            Serie
                                        </Table.HeadCell>
                                        <Table.HeadCell>
                                            Squadre
                                        </Table.HeadCell>
                                        <Table.HeadCell>
                                            Arbitri
                                        </Table.HeadCell>
                                        <Table.HeadCell>
                                            <span className="sr-only">Edit</span>
                                        </Table.HeadCell>
                                    </Table.Head>
                                    <Table.Body className="divide-y">
                                        {loading && <LoadingSpinner colSpan={5} />}
                                        {!loading && !reports.length &&
                                            <Table.Row className="text-center bg-white dark:border-gray-700 dark:bg-gray-800">
                                                <Table.Cell colSpan={5}>
                                                    Nessun report presente
                                                </Table.Cell>
                                            </Table.Row>
                                        }
                                        {reports.length > 0 &&
                                            reports.map(r =>
                                                <ReportRow
                                                    key={r._id}
                                                    report={r}
                                                    setAction={setAction}
                                                    setReport={setReport}
                                                    setShowReferee={setShowReferee}
                                                />
                                            )
                                        }
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                action === 'preview' &&
                <div className="flex flex-col gap-2">
                    <div>
                        <Button 
                            size="sm"
                            onClick={goBack}
                        >
                            Torna indietro
                        </Button>
                    </div>
                    <ReportPreview
                        report={report}
                        showReferee={showReferee}
                    />
                </div>
            }
        </main>
    )
}

export default RefereeHome;