import { configureStore } from '@reduxjs/toolkit';
import seasonReducer from '../features/season/seasonSlice';
import titleReducer from '../features/title/titleSlice';
import userReducer from '../features/user/userSlice';

export const store = configureStore({
  reducer: {
    season: seasonReducer,
    title: titleReducer,
    user: userReducer
  },
})