import React from "react";

// Components
import { Button, Table, Tooltip } from "flowbite-react";

// Icon
import { EditIcon } from "../../../images/icons/Icons";

const RefRow = ({ referee, setModalState, setReferee, toggleUserModal }) => {
    const committees = {
        'ak': 'Akranis',
        'ct': 'Catania',
        'me': 'Messina',
        'mi': 'Monti Iblei',
        'pa': 'Palermo',
        'tp': 'Trapani'
    }
    function editReferee() {
        setModalState('edit');
        setReferee(referee);
        toggleUserModal();
    }

    return (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {referee.lastname} {referee.firstname}
            </Table.Cell>
            <Table.Cell className="hidden md:table-cell">
                {referee.email}
            </Table.Cell>
            <Table.Cell className="hidden sm:table-cell">
                {committees[[referee.committee]]}
            </Table.Cell>
            <Table.Cell>
                <Tooltip content="Modifica arbitro">
                    <Button
                        color="warning"
                        onClick={editReferee}
                        size="xs"
                    >
                        <EditIcon />
                    </Button>
                </Tooltip>
            </Table.Cell>
        </Table.Row>
    )
}

const RefList = ({ referees, setModalState, setReferee, toggleUserModal }) => {

    return (
        referees.map(referee =>
            <RefRow
                key={referee._id}
                referee={referee}
                setModalState={setModalState}
                setReferee={setReferee}
                toggleUserModal={toggleUserModal}
            />
        )
    );
};

export default RefList;