import React, { useEffect, useState } from "react";
import { serviceProvider as API } from "../../API/api";
import { formatDate, sortByDate } from "../../lib/genericUtils";

// Components
import { Alert, Button, Table } from "flowbite-react";
import LoadingSpinner from "../LoadingSpinner";
import SeasonModal from "./seasons/SeasonModal";

// Icons
import { InfoIcon } from "../../images/icons/Icons";

const SeasonsRow = ({ season, setAction, setOpenModal, setSeason }) => {

    const editSeason = () => {
        setSeason(season);
        setAction('edit');
        setOpenModal(true);
    }

    const deleteSeason = () => {
        setSeason(season);
        setAction('delete');
        setOpenModal(true);
    }

    return (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {season.name}
            </Table.Cell>
            <Table.Cell className="hidden md:table-cell">
                {formatDate(season.startDate)}
            </Table.Cell>
            <Table.Cell className="hidden md:table-cell">
                {formatDate(season.endDate)}
            </Table.Cell>
            <Table.Cell>
                <div className="flex flex-row gap-2">
                    <Button
                        color="warning"
                        onClick={editSeason}
                        size="xs"
                    >
                        Modifica
                    </Button>
                    <Button
                        color="failure"
                        onClick={deleteSeason}
                        size="xs"
                    >
                        Elimina
                    </Button>
                </div>
            </Table.Cell>
        </Table.Row>
    )
}

function Seasons() {
    const [alert, setAlert] = useState({ type: '', label: '' });
    const [loading, setLoading] = useState(true);
    const [action, setAction] = useState('new');
    const [openModal, setOpenModal] = useState(false);
    const [season, setSeason] = useState({ name: '', startDate: '', endDate: '' });
    const [seasons, setSeasons] = useState([]);

    const newSeason = () => {
        setSeason({ name: '', startDate: '', endDate: '' });
        setAction('new');
        setOpenModal(true);
    }

    useEffect(() => {
        API.get('seasons', true)
            .then(res => {
                if (res.success) {
                    setSeasons(sortByDate(res.seasons, 'startDate'));
                    setLoading(false);
                } else {
                    if (res.statusCode === 404) setLoading(false);
                }
            })
    }, [])

    return (
        <div className="flex flex-col gap-2">
            {alert.type &&
                <Alert
                    className="mb-2"
                    color={alert.type}
                    icon={InfoIcon}
                >
                    <span>
                        {alert.label}
                    </span>
                </Alert>
            }
            <Table hoverable={true}>
                <Table.Head>
                    <Table.HeadCell>
                        Stagione
                    </Table.HeadCell>
                    <Table.HeadCell className="hidden md:table-cell">
                        Data di inizio
                    </Table.HeadCell>
                    <Table.HeadCell className="hidden md:table-cell">
                        Data di fine
                    </Table.HeadCell>
                    <Table.HeadCell>
                        <span className="sr-only">Edit</span>
                    </Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                    {loading && <LoadingSpinner colSpan={4} />}
                    {!loading && !seasons.length &&
                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                            <Table.Cell colSpan="4">
                                Nessuna stagione sportiva presente
                            </Table.Cell>
                        </Table.Row>
                    }
                    {seasons.length > 0 &&
                        seasons.map(s =>
                            <SeasonsRow
                                key={s._id}
                                season={s}
                                setAction={setAction}
                                setOpenModal={setOpenModal}
                                setSeason={setSeason}
                            />
                        )
                    }
                </Table.Body>
            </Table>
            <div>
                <Button
                    type="button"
                    size="sm"
                    onClick={newSeason}
                >
                    Nuova Stagione
                </Button>
            </div>
            <SeasonModal
                action={action}
                openModal={openModal}
                season={season}
                setSeasonAlert={setAlert}
                setOpenModal={setOpenModal}
                setSeason={setSeason}
                setSeasons={setSeasons}
            />
        </div >
    )
}

export default Seasons;