import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { serviceProvider as API } from "../../API/api";
import { getVote, sortByDate, sortByName } from "../../lib/genericUtils";

// Components
import { Alert, Button, Label, Select, Spinner, Table } from "flowbite-react";
import RatingsModal from "./ratings/RatingsModal";
import RatingsRow from "./ratings/RatingsRow";
import ScoresModal from "./scores/ScoresModal";
import ScoresRow from "./scores/ScoresRow";

// Icons
import { InfoIcon } from "../../images/icons/Icons";


function Ratings() {
    const season = useSelector(state => state.season);
    const [setTitle] = useOutletContext();
    const [alert, setAlert] = useState({ type: '', label: '' });
    const [action, setAction] = useState('new');
    const [average, setAverage] = useState({ rating: undefined, count: 0, votes: [] });
    const [currentVote, setCurrentVote] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [openScoreModal, setOpenScoreModal] = useState(false);
    const [rating, setRating] = useState({ season: { _id: '' }, techPts: '', discPts: '', commPts: '', totalPts: '', judgement: '', group: '', position: 1 });
    const [referee, setReferee] = useState({ _id: '' });
    const [referees, setReferees] = useState([]);
    const [selectedRole, setSelectedRole] = useState(2);
    const [score, setScore] = useState({ season: { _id: '' }, context: 'Tecnico', points: 0, reason: '' });
    const [totalUsers, setTotalUsers] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const getScore = useCallback((scores, context, startingPts = 0) => {
        const modifier = scores
            .filter(s => s.context === context)
            .reduce((prev, curr) => (prev * 100) / 100 + ((curr.points * 100) / 100), 0)
        return (startingPts + modifier).toFixed(2);
    }, [])

    const newRating = () => {
        setRating({ season: { _id: '' }, techPts: '', discPts: '', commPts: '', totalPts: '', judgement: '', group: '', position: 1 });
        setAction('new');
        setOpenModal(true);
    }

    const newScore = () => {
        setScore({ season: { _id: '' }, context: 'Tecnico', points: 0, reason: '' });
        setAction('new');
        setOpenScoreModal(true);
    }

    const changeRole = e => {
        setAverage({ rating: undefined, count: 0, votes: [] });
        setReferee({ _id: '' });
        setSelectedRole(Number(e.currentTarget.value));
    };

    const changeRef = e => {
        setAverage({ rating: undefined, count: 0, votes: [] });
        setReferee(referees.find(r => r._id.toString() === e.currentTarget.value) || { _id: '' });
        API.get(`reports?referee=${e.currentTarget.value}&startDate=${season.startDate}&endDate=${season.endDate}&valid=true`, true)
            .then(res => {
                if (res.success) {
                    const array = res.reports?.reduce((p, report) => [...p, getVote(report, report.general.first_ref._id.toString() === e.target.value ? '1st' : '2nd')], []);
                    // Se ci sono delle osservazioni validate, calcolo la media
                    if (array.length > 0) {
                        setAverage({
                            rating: ((array.reduce((p, curr) => p + curr, 0) * 100) / (array.length * 100)).toFixed(2),
                            count: array.length,
                            votes: array.join(', ')
                        })
                    } else {
                        // Altrimenti imposto a 0
                        setAverage({
                            rating: 0,
                            count: 0,
                            votes: '-'
                        })
                    }
                }
            })
            .catch(err => {
                console.error(err);
                setAlert({ type: 'failure', label: 'Qualcosa è andato storto, riprova più tardi' });
            })
    }

    useEffect(() => {
        API.get('users', true)
            .then(res => {
                res.success && setTotalUsers(sortByName(res.users, 'lastname'));
            })
            .catch(err => console.error(err));
    }, []);

    useEffect(() => {
        if (totalUsers.length > 0) {
            if (selectedRole === 2)
                setReferees(totalUsers.filter(u => u.authrole === 2));
            else
                setReferees(totalUsers.filter(u => u.authrole < 2));
        }
    }, [selectedRole, totalUsers])

    useEffect(() => {
        API.get('seasons', true)
            .then(res => {
                res.success && setSeasons(sortByDate(res.seasons, 'startDate'));
            })
            .catch(err => console.error(err));
    }, [])

    useEffect(() => {
        // Calcolo il punteggio della stagione corrente
        if (average.rating !== undefined) {
            const scores = referee.scores.filter(s => s.season?._id.toString() === season._id.toString());
            const modifier = scores.reduce((p, curr) => (p * 100 + curr.points * 100) / 100, 0);
            setCurrentVote(((average.rating * 100 + modifier * 100) / 100).toFixed(2));
        }
    }, [average.rating, referee.scores, season._id])

    useEffect(() => {
        setTitle('Gestione Valutazioni')
    }, [setTitle])


    return (
        <div className="flex flex-col gap-2">
            {alert.type &&
                <Alert
                    className="mb-2"
                    color={alert.type}
                    icon={InfoIcon}
                >
                    <span>
                        {alert.label}
                    </span>
                </Alert>
            }
            <div className="flex flex-row gap-4">
                <div
                    className="max-w-sm"
                    id="select"
                >
                    <div className="mb-2 block">
                        <Label
                            htmlFor="referees"
                            value="Tipologia Utenti"
                        />
                    </div>
                    <Select
                        id="roles"
                        value={selectedRole}
                        onChange={changeRole}
                    >
                        <option value={2}>
                            Arbitri
                        </option>
                        <option value={1}>
                            Osservatori
                        </option>
                    </Select>
                </div>
                <div
                    className="max-w-sm"
                    id="select"
                >
                    <div className="mb-2 block">
                        <Label
                            htmlFor="referees"
                            value={`Seleziona un ${selectedRole === 2 ? 'arbitro' : 'osservatore'}`}
                        />
                    </div>
                    <Select
                        id="referees"
                        value={referee?._id}
                        onChange={changeRef}
                    >
                        <option value=''>
                            -
                        </option>
                        {referees.map(r =>
                            <option key={r._id} value={r._id}>
                                {r.lastname} {r.firstname}
                            </option>
                        )}
                    </Select>
                </div>
            </div>
            <h1 className="text-center uppercase tracking-wide font-semibold">
                Punteggio Stagioni
            </h1>
            <Table hoverable={true}>
                <Table.Head>
                    <Table.HeadCell>
                        Stagione
                    </Table.HeadCell>
                    <Table.HeadCell>
                        P. Tecnico
                    </Table.HeadCell>
                    <Table.HeadCell>
                        P. Comportamentale
                    </Table.HeadCell>
                    <Table.HeadCell>
                        P. Commissione
                    </Table.HeadCell>
                    <Table.HeadCell>
                        P. Complessivo
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Valutazione
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Fascia
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Posizione
                    </Table.HeadCell>
                    <Table.HeadCell>
                        <span className="sr-only">Edit</span>
                    </Table.HeadCell>
                </Table.Head>
                <Table.Body>
                    {!referee?._id &&
                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 text-center">
                            <Table.Cell colSpan={9}>
                                Seleziona un {selectedRole === 2 ? 'arbitro' : 'osservatore'}
                            </Table.Cell>
                        </Table.Row>
                    }
                    {
                        (!referee?.ratings || referee?.ratings.length < 1) && referee?._id &&
                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 text-center">
                            <Table.Cell colSpan={9}>
                                Nessun punteggio stagione ancora inserito
                            </Table.Cell>
                        </Table.Row>
                    }
                    {referee?.ratings && referee?.ratings.length > 0 &&
                        referee.ratings.map((r, i) =>
                            <RatingsRow
                                key={r._id || `r_${i}`}
                                rating={r}
                                setAction={setAction}
                                setRating={setRating}
                                setOpenModal={setOpenModal}
                            />
                        )
                    }
                    {referee?._id &&
                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                            <Table.Cell>
                                {season.name} (in corso)
                            </Table.Cell>
                            <Table.Cell>
                                {getScore(referee?.scores || [], 'Tecnico', Number(average?.rating))}
                            </Table.Cell>
                            <Table.Cell>
                                {getScore(referee?.scores || [], 'Comportamentale')}
                            </Table.Cell>
                            <Table.Cell>
                                {getScore(referee?.scores || [], 'Commissione')}
                            </Table.Cell>
                            <Table.Cell>
                                {currentVote}
                            </Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                        </Table.Row>}
                </Table.Body>
            </Table>
            <div>
                <Button
                    type="button"
                    size="sm"
                    onClick={newRating}
                    disabled={!referee?._id}
                >
                    Nuovo Punteggio Stagione
                </Button>
            </div>
            {selectedRole === 2 &&
                <>
                    <h1 className="text-center uppercase tracking-wide font-semibold">
                        Osservazioni Stagione Corrente
                    </h1>
                    <Table hoverable={true}>
                        <Table.Head>
                            <Table.HeadCell>
                                Voti Osservazioni
                            </Table.HeadCell>
                            <Table.HeadCell>
                                Numero Osservazioni
                            </Table.HeadCell>
                            <Table.HeadCell>
                                Media attuale
                            </Table.HeadCell>
                        </Table.Head>
                        <Table.Body>
                            {!referee?._id &&
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 text-center">
                                    <Table.Cell colSpan={3}>
                                        Seleziona un {selectedRole === 2 ? 'arbitro' : 'osservatore'}
                                    </Table.Cell>
                                </Table.Row>
                            }
                            {referee?._id && average.rating === undefined &&
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 text-center">
                                    <Table.Cell colSpan={3}>
                                        <Spinner />
                                    </Table.Cell>
                                </Table.Row>
                            }
                            {average.rating !== undefined &&
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 align-middle">
                                    <Table.Cell>
                                        {average.votes}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {average.count}
                                    </Table.Cell>
                                    <Table.Cell className="font-semibold text-lg">
                                        {average.rating}
                                    </Table.Cell>
                                </Table.Row>
                            }
                        </Table.Body>
                    </Table>
                </>
            }
            <h1 className="text-center uppercase tracking-wide font-semibold mt-6">
                Valutazioni
            </h1>
            <Table hoverable={true}>
                <Table.Head>
                    <Table.HeadCell>
                        Stagione
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Ambito
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Punteggio
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Motivazione
                    </Table.HeadCell>
                    <Table.HeadCell>
                        <span className="sr-only">Edit</span>
                    </Table.HeadCell>
                </Table.Head>
                <Table.Body>
                    {!referee?._id &&
                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 text-center">
                            <Table.Cell colSpan={5}>
                                Seleziona un {selectedRole === 2 ? 'arbitro' : 'osservatore'}
                            </Table.Cell>
                        </Table.Row>
                    }
                    {
                        (!referee?.ratings || referee?.scores.length < 1) && referee?._id &&
                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 text-center">
                            <Table.Cell colSpan={5}>
                                Nessuna valutazione ancora inserita
                            </Table.Cell>
                        </Table.Row>
                    }
                    {referee?.scores && referee?.scores.length > 0 &&
                        referee.scores.map((s, i) =>
                            <ScoresRow
                                key={s._id || `s_${i}`}
                                score={s}
                                setAction={setAction}
                                setScore={setScore}
                                setOpenModal={setOpenScoreModal}
                            />
                        )
                    }
                </Table.Body>
            </Table>
            <div>
                <Button
                    type="button"
                    size="sm"
                    onClick={newScore}
                    disabled={!referee?._id}
                >
                    Nuova valutazione
                </Button>
            </div>
            {/* {referee?._id && selectedRole === 2 &&
                <>
                    <h1 className="text-center uppercase tracking-wide font-semibold mt-6">
                        Punteggio Corrente
                    </h1>
                    <Table hoverable={true}>
                        <Table.Body>
                            <Table.Row className="bg-teal-50 hover:bg-teal-100">
                                <Table.Cell className="font-bold uppercase">
                                    Totale (Media attuale +/- Punteggi Valutazioni Stagione Corrente)
                                </Table.Cell>
                                <Table.Cell>
                                    {average.rating === undefined &&
                                        <Spinner />
                                    }
                                    {average.rating !== undefined &&
                                        <span className="text-xl font-semibold">
                                            {currentVote}
                                        </span>
                                    }
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </>
            } */}
            <RatingsModal
                action={action}
                openModal={openModal}
                rating={rating}
                referee={referee}
                seasons={seasons}
                setRatingAlert={setAlert}
                setOpenModal={setOpenModal}
                setRating={setRating}
                setReferee={setReferee}
                setReferees={setReferees}
            />
            <ScoresModal
                action={action}
                openModal={openScoreModal}
                referee={referee}
                score={score}
                seasons={seasons}
                setScoreAlert={setAlert}
                setOpenModal={setOpenScoreModal}
                setReferee={setReferee}
                setReferees={setReferees}
                setScore={setScore}
            />
        </div>
    )
}

export default Ratings;