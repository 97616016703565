import React from "react";

// Components
import { Button, Table, Tooltip } from "flowbite-react";

// Icons
import { DeleteIcon, EditIcon } from "../../../images/icons/Icons";


function ScoresRow({ score, setAction, setScore, setOpenModal }) {

    const editScore = () => {
        setAction('edit');
        setScore(score);
        setOpenModal(true);
    }

    const deleteScore = () => {
        setAction('delete');
        setScore(score);
        setOpenModal(true);
    }

    return (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell>
                <Tooltip
                    content={`Ins. il ${new Date(score.inserted_at).toLocaleDateString('it-IT', {hour: '2-digit', minute: '2-digit'})} - Ult. mod. il ${new Date(score.updated_at).toLocaleDateString('it-IT', {hour: '2-digit', minute: '2-digit'})}`}
                >
                {score.season?.name}
                </Tooltip>
            </Table.Cell>
            <Table.Cell>
                {score.context}
            </Table.Cell>
            <Table.Cell>
                {score.points}
            </Table.Cell>
            <Table.Cell>
                {score.reason}
            </Table.Cell>
            <Table.Cell>
                <Button.Group>
                    <Button
                        color="warning"
                        size="xs"
                        onClick={editScore}
                    >
                        <EditIcon />
                    </Button>
                    <Button
                        color="failure"
                        size="xs"
                        onClick={deleteScore}
                    >
                        <DeleteIcon />
                    </Button>
                </Button.Group>
            </Table.Cell>
        </Table.Row>
    )
}

export default ScoresRow;