import React, { useCallback, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { assign as assignUser } from "../features/user/userSlice";

// Api
import { serviceProvider as API } from "../API/api";

// Components
import { Button, Label } from "flowbite-react";

// Validation
import validateInput from "../lib/validateUtils";

/* 
// Validating input
*/
const checkInput = (inputId, value) => {
    const checks = [
        { field: 'firstname', tests: ['notEmpty'] },
        { field: 'lastname', tests: ['notEmpty'] },
        { field: 'email', tests: ['notEmpty', 'isEmail'] },
        { field: 'password', tests: ['notEmpty', 'minLength'] },
        { field: 'verifyPassword', tests: ['notEmpty'] }
    ];
    const inputCheck = checks.find(el => el.field === inputId);
    if (!inputCheck) return { success: true };
    return validateInput(value, inputCheck.tests);
}

function EditProfile() {
    const dispatch = useDispatch();
    const [error, setError] = useState();
    const loggedUser = useSelector(state => state.user);
    const [message, setMessage] = useState();
    const [style, setStyle] = useState({
        firstname: undefined,
        lastname: undefined,
        email: undefined
    });
    const [testing, setTesting] = useState({ status: false, validationResults: [] });
    const [user, setUser] = useState({
        firstname: loggedUser.firstname,
        lastname: loggedUser.lastname,
        email: loggedUser.email
    });

    const manageInput = e => {
        const { id, value } = e.currentTarget;
        setUser(prevState => ({
            ...prevState,
            [id]: value
        }));
    }

    const handleBlur = e => {
        const inputId = e.currentTarget.id;
        const value = e.currentTarget.value;
        const test = checkInput(inputId, value);
        confirmBlur(test, inputId, setError, setStyle);
    }

    const confirmBlur = (test, inputId, setError, setStyle) => {
        if (test.success) {
            setError(undefined);
            setStyle(prevState => ({
                ...prevState,
                [inputId]: 'form-input-success'
            }));
        } else {
            setError(test.msg);
            setStyle(prevState => ({
                ...prevState,
                [inputId]: 'form-input-danger'
            }));
        }
    }

    const finalValidate = useCallback((setError, setStyle, setTesting) => {
        Object.entries(user).forEach(([key, value]) => {
            const test = checkInput(key, value);
            confirmBlur(test, key, setError, setStyle);
            setTesting(prevState => ({
                ...prevState,
                validationResults: [
                    ...prevState.validationResults, test.success
                ]
            }));
        });
    }, [user])

    // Form sending
    const formSending = e => {
        e.preventDefault();
        setTesting({ status: true, validationResults: [] });
    }

    // Verifica dei test effettuati
    useEffect(() => {
        const { status, validationResults } = testing;
        if (status && validationResults.length > 2) { // Allora tutti i test sono stati effettuati
            setTesting(prevState => ({
                ...prevState,
                status: false
            }));
            if (validationResults.every(el => el === true)) {
                // I dati sono validati, quindi possiamo effettuare la modifica
                const body = JSON.stringify(user);
                API.update(`users/${loggedUser._id}`, body, true)
                    .then(res => {
                        if (res.success) {
                            dispatch(assignUser(res.user));
                            setError(undefined);
                            setMessage('Le modifiche sono state applicate');
                        } else {
                            setMessage(undefined);
                            setError(res?.msg || 'Qualcosa è andato storto, si prega di riprovare')
                        }
                    })
                    .catch(err => console.error(err));
            } else {
                setError('Verifica i campi inseriti');
            }
        }
    }, [loggedUser._id, dispatch, testing, setTesting, user]);

    useEffect(() => {
        if (testing.status) {
            finalValidate(setError, setStyle, setTesting);
        }
    }, [finalValidate, testing.status])

    return (
        <form>
            <div className="mb-2 py-2 px-6 lg:px-8 bg-gray-50/70 shadow-md sm:rounded-lg">
                <h3 className="generic-title mb-5 text-center">Modifica Profilo</h3>
                {
                    error && (
                        <div className="danger-alert dark:bg-red-200 dark:text-red-800" role="alert">
                            <span className="font-medium">Attenzione!</span> {error}.
                        </div>
                    )
                }
                {
                    message && (
                        <div className="success-alert dark:bg-green-200 dark:text-green-800">
                            {message}
                        </div>
                    )
                }
                <div className="mb-4 grid grid-cols-2 gap-1 items-center">
                    <Label htmlFor="firstname" value="Nome" />
                    <input
                        className={`block p-2 w-full ${style.firstname || 'form-select'} text-sm`}
                        id="firstname"
                        onBlur={handleBlur}
                        onChange={manageInput}
                        type="text"
                        value={user.firstname}
                        required
                    />
                    <Label htmlFor="lastname" value="Cognome" />
                    <input
                        className={`block p-2 w-full ${style.lastname || 'form-select'} text-sm`}
                        id="lastname"
                        onBlur={handleBlur}
                        onChange={manageInput}
                        type="text"
                        value={user.lastname}
                        required
                    />
                    <Label htmlFor="email" value="E-mail" />
                    <input
                        className={`block p-2 w-full ${style.email || 'form-select'} text-sm`}
                        id="email"
                        onBlur={handleBlur}
                        onChange={manageInput}
                        type="text"
                        value={user.email}
                        required
                    />
                </div>
                <Button
                    onClick={formSending}
                    size="sm"
                    type="submit"
                >
                    Invia
                </Button>
            </div>
        </form>
    )
}

function EditPassword() {
    const loggedUser = useSelector(state => state.user);
    const [error, setError] = useState();
    const [message, setMessage] = useState();
    const [password, setPassword] = useState('');
    const [style, setStyle] = useState({
        password: undefined,
        verifyPassword: undefined
    });
    const [testing, setTesting] = useState({ status: false, validationResults: [] });
    const [verifyPassword, setVerifyPassword] = useState('');

    const passwordInput = e => {
        setPassword(e.currentTarget.value);
    }

    const verifyPasswordInput = e => {
        setVerifyPassword(e.currentTarget.value);
    }

    const handleBlur = e => {
        const inputId = e.currentTarget.id;
        const value = e.currentTarget.value;
        const test = checkInput(inputId, value);
        confirmBlur(test, inputId, setError, setStyle);
    }

    const confirmBlur = (test, inputId, setError, setStyle) => {
        if (test.success) {
            setError(undefined);
            setStyle(prevState => ({
                ...prevState,
                [inputId]: 'form-input-success'
            }));
        } else {
            setError(test.msg);
            setStyle(prevState => ({
                ...prevState,
                [inputId]: 'form-input-danger'
            }));
        }
    }

    const finalValidate = useCallback((setError, setStyle, setTesting) => {
        Object.entries({ password, verifyPassword }).forEach(([key, value]) => {
            const test = checkInput(key, value);
            confirmBlur(test, key, setError, setStyle);
            setTesting(prevState => ({
                ...prevState,
                validationResults: [
                    ...prevState.validationResults, test.success
                ]
            }));
        });
    }, [password, verifyPassword]);

    // Form sending
    const formSending = e => {
        e.preventDefault();
        setTesting({ status: true, validationResults: [] });
    }

    // Verifica dei test effettuati
    useEffect(() => {
        const { status, validationResults } = testing;
        if (status && validationResults.length > 1) { // Allora tutti i test sono stati effettuati
            setTesting(prevState => ({
                ...prevState,
                status: false
            }));
            if (validationResults.every(el => el === true)) {
                // Possiamo procedere alla modifica
                const body = JSON.stringify({ password: password });
                API.update(`/users/${loggedUser._id}`, body, true)
                    .then(res => {
                        if (res.success) {
                            setError(undefined);
                            setMessage('La password è stata modificata con successo');
                        } else {
                            setMessage(undefined);
                            setError(res?.msg || 'Qualcosa è andato storto, si prega di riprovare');
                        }
                    })
                    .catch(err => console.error(err));
            } else {
                setError('Verifica i campi inseriti');
            }
        }
    }, [loggedUser._id, password, testing, setTesting]);

    useEffect(() => {
        if (testing.status) {
            const tests = validateInput({ password, verifyPassword }, ['verifyPassword', 'checkPassword']);
            if (tests.success) {
                finalValidate(setError, setStyle, setTesting);
            } else {
                setStyle({
                    password: 'form-input-danger',
                    verifyPassword: 'form-input-danger'
                });
                setMessage(undefined)
                setError(tests?.msg || 'Verifica i dati inseriti');
                setTesting(prevState => ({
                    ...prevState,
                    status: false
                }));
            }
        }
    }, [finalValidate, password, testing.status, verifyPassword])

    return (
        <form>
            <div className="mb-2 py-2 px-6 lg:px-8 bg-gray-50/70 shadow-md sm:rounded-lg">
                <h3 className="generic-title mb-5 text-center">Modifica Password</h3>
                {
                    error && (
                        <div className="danger-alert dark:bg-red-200 dark:text-red-800" role="alert">
                            <span className="font-medium">Attenzione!</span> {error}.
                        </div>
                    )
                }
                {
                    message && (
                        <div className="success-alert dark:bg-green-200 dark:text-green-800" role="alert">
                            {message}
                        </div>
                    )
                }
                <div className="mb-4 grid md:grid-cols-2 gap-1 items-center">
                    <Label htmlFor="password" value="Nuova Password" />
                    <input
                        className={`block p-2 w-full ${style.password || 'form-select'} text-sm`}
                        id="password"
                        onBlur={handleBlur}
                        onChange={passwordInput}
                        type="password"
                        value={password}
                        required
                    />
                    <Label htmlFor="verifypassword" value="Verifica la password" />
                    <input
                        className={`block p-2 w-full ${style.verifyPassword || 'form-select'} text-sm`}
                        id="verifyPassword"
                        onBlur={handleBlur}
                        onChange={verifyPasswordInput}
                        type="password"
                        value={verifyPassword}
                        required
                    />
                    <p
                        id="helper-text-explanation"
                        className="mt-2 text-sm text-gray-500 dark:text-gray-400 col-span-2"
                    >
                        La password deve contenere minimo 8 caratteri, un numero e una lettera maiuscola.
                    </p>
                </div>
                <Button
                    onClick={formSending}
                    size="sm"
                    type="submit"
                >
                    Invia
                </Button>
            </div>
        </form>
    )
}

function Profile() {
    const [setTitle] = useOutletContext();

    // Imposta il titolo
    useEffect(() => {
        setTitle('Modifica Profilo');
    }, [setTitle]);

    return (
        <main className="mx-auto w-3/4 md:w-4/6 lg:w-1/2 px-5 py-20 lg:px-10 md:py-48">
            <EditProfile />
            <EditPassword />
        </main>
    )
}

export default Profile;