import React from "react";

// Components
import { Button, Table } from "flowbite-react";

// Icons
import { DeleteIcon, EditIcon } from "../../../images/icons/Icons";


function RatingsRow({ rating, setAction, setRating, setOpenModal }) {

    const editRating = () => {
        setAction('edit');
        setRating(rating);
        setOpenModal(true);
    }

    const deleteRating = () => {
        setAction('delete');
        setRating(rating);
        setOpenModal(true);
    }

    return (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell>
                {rating.season?.name}
            </Table.Cell>
            <Table.Cell>
                {rating.techPts}
            </Table.Cell>
            <Table.Cell>
                {rating.discPts}
            </Table.Cell>
            <Table.Cell>
                {rating.commPts}
            </Table.Cell>
            <Table.Cell>
                {rating.totalPts}
            </Table.Cell>
            <Table.Cell>
                {rating.judgement}
            </Table.Cell>
            <Table.Cell>
                {rating.group}
            </Table.Cell>
            <Table.Cell>
                {rating.position}
            </Table.Cell>
            <Table.Cell>
                <Button.Group>
                    <Button
                        color="warning"
                        size="xs"
                        onClick={editRating}
                    >
                        <EditIcon />
                    </Button>
                    <Button
                        color="failure"
                        size="xs"
                        onClick={deleteRating}
                    >
                        <DeleteIcon />
                    </Button>
                </Button.Group>
            </Table.Cell>
        </Table.Row>
    )
}

export default RatingsRow;