const mainConfigs = 
{
    PROTOCOL: 'https',
    PATH: 'valutazioniudg.sicilia.it/api/v1'
}

/* const mainConfigs = 
{
    PROTOCOL: 'https',
    PATH: 'valutazioniudg.sicilia.it/api/v1'
} */

export default mainConfigs;