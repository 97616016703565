import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    content: 'Portale Osservazioni'
}

const titleSlice = createSlice({
    name: 'title',
    initialState,
    reducers: {
        assign: (state, action) => action.payload
    }
});

export const { assign } = titleSlice.actions;
export default titleSlice.reducer;