import React from 'react';
import { useSelector } from 'react-redux';

// Icon
import { ReactComponent as BallIcon } from '../../images/icons/volleyball.svg';

const WelcomeBox = () => {
    const user = useSelector(state => state.user);

    return (
        <div className="rounded shadow bg-gray-900 p-8 w-80 md:h-96">
            <span className="font-medium text-2xl text-gray-200 block mb-16">
                Benvenuto <span className="uppercase tracking-wide">{ user.firstname }</span>
            </span>
            <BallIcon
                alt="Volleyball Icon" 
                className="w-1/3 animate-bounce mx-auto mb-10"
            />
            <span className="font-medium text-lg text-gray-200">
                Scegli cosa fare dal menu principale.
            </span>
        </div>
    )
}

export default WelcomeBox;