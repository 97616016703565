import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";

// Components
import LoginBox from "./LoginBox";
import WelcomeBox from "./user/WelcomeBox";

// Images
import courtImage from '../images/court.jpg';

const Header = () => {
    const { _id: userId } = useSelector(state => state.user);
    const [setTitle] = useOutletContext();

    useEffect(() => {
        setTitle('Portale Osservazioni');
    }, [setTitle])
    
    return (
            <header className="container mx-auto bg-gray-50/70 rounded-lg shadow-lg text-center flex flex-wrap items-center justify-center gap-4 px-5 py-20 lg:px-10 md:py-48">
                <div className="py-10">
                    <img src={courtImage} className="rounded w-80 md:w-96 md:h-96" alt="Campo di Gioco" />
                </div>
                <div>  
                    { !userId && <LoginBox /> }
                    { userId && <WelcomeBox /> }
                </div>
            </header>
    )
}

export default Header;