import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import { store } from './app/store';
import './index.css';

// Components
import Accounts from './components/admin/Accounts';
import App from './App';
import Header from './components/Header';
import Profile from './components/Profile';
import Ratings from './components/admin/Ratings';
import RefereeHome from './components/user/RefereeHome';
import Referees from './components/admin/Referees';
import Reports from './components/user/Reports';
import Seasons from './components/admin/Seasons';
import UserRatings from './components/user/UserRatings';

// Auth Components
import { RequireAuth } from './components/auth/RequireAuth';
import { RequireUser } from './components/auth/RequireAuth';


const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
          <Routes>
            <Route path='/' element={<App />}>
              <Route index element={<Header />} />
              <Route path="home" element={<RequireUser role={2}><RefereeHome /></RequireUser>} />
              <Route path="accounts" element={<RequireAuth role={0}><Accounts /></RequireAuth>} />
              <Route path="arbitri" element={<RequireAuth role={0}><Referees /></RequireAuth>} />
              <Route path="report" element={<RequireAuth role={1}><Reports /></RequireAuth>} />
              <Route path="profilo" element={<RequireAuth role={2}><Profile /></RequireAuth>} />
              <Route path="stagioni-sportive" element={<RequireAuth role={0}><Seasons /></RequireAuth>} />
              <Route path="valutazioni" element={<RequireAuth role={0}><Ratings /></RequireAuth>} />
              <Route path="valutazioni-arbitri" element={<RequireAuth role={2}><UserRatings /></RequireAuth>} />
            </Route>
          </Routes>
      </Provider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
