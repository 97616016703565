const series = {
    'CM': 'C/M',
    'CF': 'C/F',
    'DM': 'D/M',
    'DF': 'D/F',
    'CSM': 'Coppa Sicilia / M',
    'CSF': 'Coppa Sicilia / F',
    'U18M': 'Under 18/M',
    'U18F': 'Under 18/F',
    'U16M': 'Under 16/M',
    'U16F': 'Under 16/F',
    'U14M': 'Under 14/M',
    'PCM': 'Playoff C/M',
    'PCF': 'Playoff C/M',
    'PDM': 'Playoff D/M',
    'PDF': 'Playoff D/F',
    'OUTCM': 'Playout C/M',
    'OUTCF': 'Playout C/F',
    'OUTDM': 'Playout D/M',
    'OUTDF': 'Playout D/F',
    'FPCM': 'Fin. Playoff C/M',
    'FPCF': 'Fin. Playoff C/F',
    'FPDM': 'Fin. Playoff D/M',
    'FPDF': 'Fin. Playoff D/F',
    'FU18M': 'Fin. Under 18/M',
    'FU18F': 'Fin. Under 18/F',
    'FU16M': 'Fin. Under 16/M',
    'FU16F': 'Fin. Under 16/F',
    'FU14M': 'Fin. Under 14/M',
    'FU14F': 'Fin. Under 14/F',
};

export default series;