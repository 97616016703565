import { useState } from "react";
import { serviceProvider as API } from "../../../API/api";
import { formToIsoString, isoToForm, sortByDate } from "../../../lib/genericUtils";

// Components
import { Alert, Button, Modal, Label, TextInput } from "flowbite-react";

// Icons
import { InfoIcon } from "../../../images/icons/Icons";

function SeasonModal({ action, openModal, season, setOpenModal, setSeason, setSeasonAlert, setSeasons }) {
    const [alert, setAlert] = useState({ type: '', label: '' });

    const handleInput = e => {
        const { id, value } = e.currentTarget;
        setSeason(prevState => ({
            ...prevState,
            [id]: value
        }))
    }

    const handleDate = e => {
        const { id, value } = e.currentTarget;
        setSeason(prevState => ({
            ...prevState,
            [id]: id === 'startDate'
                ? formToIsoString(`${value}T00:00`)
                : formToIsoString(`${value}T23:59`)
        }))
    }

    const handleSubmit = e => {
        e.preventDefault();
        if (new Date(season.endDate) < new Date(season.startDate)) {
            setAlert({ type: 'warning', label: 'Verifica le date inserite' });
            return;
        }
        switch (action) {
            case 'edit':
                API.update(`seasons/${season._id}`, JSON.stringify(season), true)
                    .then(res => {
                        if (res.success) {
                            setSeasons(prevState => sortByDate([
                                ...prevState.filter(s => s._id !== season._id),
                                res.season
                            ], 'startDate'));
                            setSeasonAlert({ type: 'success', label: 'La stagione è stata modificata' });
                            setOpenModal(false);
                        } else {
                            setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto, riprova più tardi' });
                        }
                    })
                break;
            case 'delete':
                API.delete(`seasons/${season._id}`, JSON.stringify(season), true)
                    .then(res => {
                        if (res.success) {
                            setSeasons(prevState => prevState.filter(s => s._id !== season._id));
                            setSeasonAlert({ type: 'success', label: 'La stagione è stata eliminata' });
                            setOpenModal(false);
                        } else {
                            setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto, riprova più tardi' });
                        }
                    })
                break;
            default:
                API.insert('seasons', JSON.stringify(season), true)
                    .then(res => {
                        if (res.success) {
                            setSeasons(prevState => sortByDate([
                                ...prevState,
                                res.season
                            ], 'startDate'));
                            setSeasonAlert({ type: 'success', label: 'La stagione è stata inserita' });
                            setOpenModal(false);
                        } else {
                            setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto, riprova più tardi' });
                        }
                    })
        }
    }

    return (
        <Modal
            show={openModal}
            size='lg'
            popup={true}
            onClose={() => setOpenModal(false)}
        >
            <Modal.Header>
                <div className="ml-4 my-2">
                    {action === 'new' ? 'Nuova' : action === 'edit' ? 'Modifica' : 'Elimina'} Stagione Sportiva
                </div>
            </Modal.Header>
            <Modal.Body>
                {alert.type &&
                    <Alert
                        color={alert.type}
                        icon={InfoIcon}
                    >
                        <span>
                            <span className="font-medium">
                                Attenzione!
                            </span>
                            {' '}
                            {alert.label}
                        </span>
                    </Alert>
                }
                <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
                    <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="name"
                                    value="Nome"
                                />
                            </div>
                            <TextInput
                                id="name"
                                type="text"
                                required={true}
                                value={season.name}
                                onChange={handleInput}
                                disabled={action === 'delete' ? true : false}
                            />
                        </div>
                        <div className="flex flex-row gap-4">
                            <div className="basis-1/2">
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="startDate"
                                        value="Data di inizio"
                                    />
                                </div>
                                <TextInput
                                    id="startDate"
                                    type="date"
                                    required={true}
                                    value={season.startDate ? isoToForm(season.startDate) : ''}
                                    onChange={handleDate}
                                    onKeyDown={e => e.preventDefault()}
                                    disabled={action === 'delete' ? true : false}
                                />
                            </div>
                            <div className="basis-1/2">
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="endDate"
                                        value="Data di fine"
                                    />
                                </div>
                                <TextInput
                                    id="endDate"
                                    type="date"
                                    required={true}
                                    value={season.endDate ? isoToForm(season.endDate) : ''}
                                    onChange={handleDate}
                                    onKeyDown={e => e.preventDefault()}
                                    disabled={action === 'delete' ? true : false}
                                />
                            </div>
                        </div>
                        {action === 'delete' &&
                            <Alert color="warning">
                                Vuoi eliminare questa stagione sportiva?
                            </Alert>
                        }
                        <Button type="submit">
                            Invia
                        </Button>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default SeasonModal