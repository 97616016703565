import React from 'react';

const OrangeBar = () => {
    return (
        <main className="bg-gradient-to-r to-orange-500 from-orange-300 py-1">
            <div className="container">
            </div>
        </main>
    )
}

export default OrangeBar;