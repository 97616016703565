import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { assign as assignUser } from "../features/user/userSlice";

// Api
import { authProvider as AUTH, serviceProvider as API } from '../API/api';

// Components
import { Alert } from "flowbite-react";

const icons = [{
    status: true,
    svg: (
        <svg
            className="absolute top-10 hover:text-gray-500 right-0 w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z">
            </path>
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z">
            </path>
        </svg>)
}, {
    status: false,
    svg: (
        <svg
            className="absolute top-10 hover:text-gray-500 right-0 w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21">
            </path>
        </svg>)
}];

const LoginBox = () => {
    const [alert, setAlert] = useState({ type: '', label: '' });
    const [error, setError] = useState(undefined);
    const [eyeOpen, setEyeOpen] = useState(true);
    const [forgotPassword, setForgotPassword] = useState(false);
    const [icon, setIcon] = useState(icons.find(i => !i.status).svg);
    const [password, setPassword] = useState('');
    const [passwordType, setPasswordType] = useState('password');
    const [email, setEmail] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const toggleEyeOpen = () => {
        setEyeOpen(!eyeOpen);
        const svg = icons.find(i => i.status === eyeOpen).svg;
        setIcon(svg);
        eyeOpen && setPasswordType('text');
        !eyeOpen && setPasswordType('password');
    }

    const handleInput = e => {
        setAlert({ type: '', label: '' });
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        if (name === 'email') setEmail(value);
        if (name === 'password') setPassword(value);
    }

    const handleSubmit = async e => {
        e.preventDefault();
        setError(undefined);
        const body = forgotPassword ? JSON.stringify({ email }) : JSON.stringify({ email, password });
        switch (forgotPassword) {
            case true:
                API.insert('password-recovery', body)
                    .then(res => {
                        setAlert({ type: res.success ? 'success' : 'failure', label: res.msg });
                    })
                    .catch(err => console.error(err));
                break;
            case false:
                AUTH.signin(body, setError)
                    .then(user => {
                        if (user) {
                            dispatch(assignUser(user));
                            const destination = user.authrole < 2 ? '/report' : '/home';
                            navigate(destination);
                        }
                    })
                    .catch(err => console.error(err));
                break;
            default:
        }
    }

    const toggleForgetPw = () => {
        setError(undefined);
        setForgotPassword(!forgotPassword);
    }

    return (
        <form className="rounded shadow bg-gray-900 p-8 w-80" onSubmit={handleSubmit}>
            {!forgotPassword &&
                <>
                    <div className="mb-6">
                        {error && <Alert color='failure'>{error}</Alert>}
                        <div>
                            <label htmlFor="email" className="text-sm font-medium text-gray-200 block mb-2">E-Mail</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                value={email}
                                onChange={handleInput}
                                required={true}
                            />
                        </div>
                    </div>
                    <div className="mb-6 relative">
                        <div>
                            <label htmlFor="password" className="text-sm font-medium text-gray-200 block mb-2">Password</label>
                            <input
                                type={passwordType}
                                id="password"
                                name="password"
                                className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 pr-7"
                                value={password}
                                onChange={handleInput}
                                required={true}
                            />
                            <div onClick={toggleEyeOpen}>
                                {icon}
                            </div>
                        </div>
                    </div>
                    <div className="mb-6 relative text-gray-200">
                        <button type="button" className="hover:underline" onClick={toggleForgetPw}>
                            Password dimenticata?
                        </button>
                    </div>
                    <button type="submit" className="block w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Invia</button>
                </>
            }
            {forgotPassword &&
                <div className="mb-6 flex flex-col gap-1">
                    {error && <div className="rounded p-1 bg-red-400 bg-opacity-25 mb-3 ring-4 ring-red-500 text-gray-200">{error}</div>}
                    {alert.type &&
                        <Alert color={alert.type}>
                            <span>{alert.label}</span>
                        </Alert>
                    }
                    <div>
                        <button
                            className="block text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 mb-8 text-center"
                            onClick={toggleForgetPw}
                        >
                            <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M10 19l-7-7m0 0l7-7m-7 7h18"
                                >
                                </path>
                            </svg>
                        </button>
                    </div>
                    <label htmlFor="email" className="text-sm font-medium text-gray-200 block mb-2">Inserisci il tuo <span className="underline">indirizzo e-mail</span> per recuperare l'accesso al tuo account</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-6"
                        value={email}
                        onChange={handleInput}
                        required={true}
                    />
                    <button type="submit" className="block w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Invia</button>
                </div>
            }
        </form>
    );
}

export default LoginBox;