import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { serviceProvider as API } from "../../API/api";
import { getVote } from "../../lib/genericUtils";

// Components
import { Spinner, Table, Tabs } from "flowbite-react";
import ScoresRow from "./ratings/ScoresRow";


function UserRatings() {
    const [setTitle] = useOutletContext();
    const [average, setAverage] = useState({ rating: undefined, count: 0, votes: [] });
    const [currentVote, setCurrentVote] = useState(0);
    const user = useSelector(state => state.user);
    const season = useSelector(state => state.season);
    const getScore = useCallback((scores, context, startingPts = 0) => {
        const modifier = scores
            .filter(s => s.context === context)
            .reduce((prev, curr) => (prev * 100) / 100 + ((curr.points * 100) / 100), 0)
        return (startingPts + modifier).toFixed(2);
    }, [])

    useEffect(() => {
        setTitle('Valutazioni e posizioni')
    }, [setTitle])

    useEffect(() => {
        // Calcolo Media
        if (average?.rating === undefined) {
            API.get(`reports?referee=${user?._id}&startDate=${season.startDate}&endDate=${season.endDate}&valid=true`, true)
                .then(res => {
                    if (res.success) {
                        const array = res.reports?.reduce((p, report) => [...p, getVote(report, report.general.first_ref._id.toString() === user?._id.toString() ? '1st' : '2nd')], []);
                        // Se ci sono delle osservazioni validate, calcolo la media
                        if (array.length > 0) {
                            setAverage({
                                rating: ((array.reduce((p, curr) => p + curr, 0) * 100) / (array.length * 100)).toFixed(2),
                                count: array.length,
                                votes: array.join(', ')
                            })
                        } else {
                            // Altrimenti imposto a 0
                            setAverage({
                                rating: 0,
                                count: 0,
                                votes: '-'
                            })
                        }
                    }
                })
                .catch(err => {
                    console.error(err);
                })
        }
    }, [average, season, user])

    useEffect(() => {
        // Calcolo il punteggio della stagione corrente
        if (average.rating !== undefined) {
            const scores = user.scores.filter(s => s.season?._id.toString() === season._id.toString());
            const modifier = scores.reduce((p, curr) => (p * 100 + curr.points * 100) / 100, 0);
            setCurrentVote(((average.rating * 100 + modifier * 100) / 100).toFixed(2));
        }
    }, [average.rating, user.scores, season._id])

    return (
        <div className="flex flex-col gap-2">
            {user?.ratings.length > 0 &&
                <>
                    <h1 className="text-center uppercase tracking-wide font-semibold mt-6">
                        Punteggi
                    </h1>
                    <Tabs.Group>
                        {user?.ratings.map(r =>
                            <Tabs.Item
                                key={r._id}
                                title={r.season.name}
                            >
                                {/* <dl className="max-w-md text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
                                    <div className="flex flex-col pb-3">
                                        <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">Punteggio Tecnico</dt>
                                        <dd className="text-lg font-semibold">{r.techPts}</dd>
                                    </div>
                                    <div className="flex flex-col py-3">
                                        <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">Punteggio Comportamentale</dt>
                                        <dd className="text-lg font-semibold">{r.discPts}</dd>
                                    </div>
                                    <div className="flex flex-col py-3">
                                        <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">Punteggio Commissione Regionale</dt>
                                        <dd className="text-lg font-semibold">{r.commPts}</dd>
                                    </div>
                                    <div className="flex flex-col py-3">
                                        <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">Punteggio Complessivo</dt>
                                        <dd className="text-lg font-semibold">{r.totalPts}</dd>
                                    </div>
                                    <div className="flex flex-col py-3">
                                        <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">Valutazione</dt>
                                        <dd className="text-lg font-semibold">{r.judgement}</dd>
                                    </div>
                                    <div className="flex flex-col py-3">
                                        <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">Fascia d'appartenenza</dt>
                                        <dd className="text-lg font-semibold">{r.group}</dd>
                                    </div>
                                    <div className="flex flex-col py-3">
                                        <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">Posizione</dt>
                                        <dd className="text-lg font-semibold">{r.position}</dd>
                                    </div>
                                </dl> */}
                                <Table hoverable={true}>
                                    <Table.Head>
                                        <Table.HeadCell colSpan={2}>
                                            Stagione {r.season.name}
                                        </Table.HeadCell>
                                    </Table.Head>
                                    <Table.Body>
                                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                            <Table.Cell className="font-semibold text-xs uppercase tracking-wide">
                                                Punteggio Tecnico
                                            </Table.Cell>
                                            <Table.Cell>
                                                {r.techPts}
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                            <Table.Cell className="font-semibold text-xs uppercase tracking-wide">
                                                Punteggio Comportamentale
                                            </Table.Cell>
                                            <Table.Cell>
                                                {r.discPts}
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                            <Table.Cell className="font-semibold text-xs uppercase tracking-wide">
                                                Punteggio Commissione
                                            </Table.Cell>
                                            <Table.Cell>
                                                {r.commPts}
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                            <Table.Cell className="font-semibold text-xs uppercase tracking-wide">
                                                Punteggio Complessivo
                                            </Table.Cell>
                                            <Table.Cell>
                                                {r.totalPts}
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                            <Table.Cell className="font-semibold text-xs uppercase tracking-wide">
                                                Valutazione
                                            </Table.Cell>
                                            <Table.Cell>
                                                {r.judgement}
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                            <Table.Cell className="font-semibold text-xs uppercase tracking-wide">
                                                Fascia
                                            </Table.Cell>
                                            <Table.Cell>
                                                {r.group}
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                            <Table.Cell className="font-semibold text-xs uppercase tracking-wide">
                                                Posizione
                                            </Table.Cell>
                                            <Table.Cell>
                                                {r.position}
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </Tabs.Item>
                        )}
                        <Tabs.Item title={`${season.name} (in corso)`}>
                            {/* <dl className="max-w-md text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
                                <div className="flex flex-col pb-3">
                                    <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">Punteggio Tecnico</dt>
                                    <dd className="text-lg font-semibold">{getScore(user.scores, 'Tecnico', Number(average?.rating))}</dd>
                                </div>
                                <div className="flex flex-col py-3">
                                    <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">Punteggio Comportamentale</dt>
                                    <dd className="text-lg font-semibold">{getScore(user.scores, 'Comportamentale')}</dd>
                                </div>
                                <div className="flex flex-col py-3">
                                    <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">Punteggio Commissione Regionale</dt>
                                    <dd className="text-lg font-semibold">{getScore(user.scores, 'Commissione')}</dd>
                                </div>
                                <div className="flex flex-col py-3">
                                    <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">Punteggio Complessivo</dt>
                                    <dd className="text-lg font-semibold">{currentVote}</dd>
                                </div>
                            </dl> */}
                            <Table hoverable={true}>
                                <Table.Head>
                                    <Table.HeadCell colSpan={2}>
                                        Stagione {`${season.name} (in corso)`}
                                    </Table.HeadCell>
                                </Table.Head>
                                <Table.Body>
                                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                        <Table.Cell className="font-semibold text-xs uppercase tracking-wide">
                                            Punteggio Tecnico
                                        </Table.Cell>
                                        <Table.Cell>
                                            {getScore(user.scores, 'Tecnico', Number(average?.rating))}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                        <Table.Cell className="font-semibold text-xs uppercase tracking-wide">
                                            Punteggio Comportamentale
                                        </Table.Cell>
                                        <Table.Cell>
                                            {getScore(user.scores, 'Comportamentale')}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                        <Table.Cell className="font-semibold text-xs uppercase tracking-wide">
                                            Punteggio Commissione
                                        </Table.Cell>
                                        <Table.Cell>
                                            {getScore(user.scores, 'Commissione')}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                        <Table.Cell className="font-semibold text-xs uppercase tracking-wide">
                                            Punteggio Complessivo
                                        </Table.Cell>
                                        <Table.Cell>
                                            {currentVote}
                                        </Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </Tabs.Item>
                    </Tabs.Group>
                </>
            }
            <h1 className="text-center uppercase tracking-wide font-semibold">
                Osservazioni Stagione Corrente
            </h1>
            <Table hoverable={true}>
                <Table.Head>
                    <Table.HeadCell>
                        Voti Osservazioni
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Numero Osservazioni
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Media attuale
                    </Table.HeadCell>
                </Table.Head>
                <Table.Body>
                    {user?._id && average.rating === undefined &&
                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 text-center">
                            <Table.Cell colSpan={3}>
                                <Spinner />
                            </Table.Cell>
                        </Table.Row>
                    }
                    {average.rating !== undefined &&
                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 align-middle">
                            <Table.Cell>
                                {average.votes}
                            </Table.Cell>
                            <Table.Cell>
                                {average.count}
                            </Table.Cell>
                            <Table.Cell className="font-semibold text-lg">
                                {average.rating}
                            </Table.Cell>
                        </Table.Row>
                    }
                </Table.Body>
            </Table>
            <h1 className="text-center uppercase tracking-wide font-semibold mt-6">
                Valutazioni
            </h1>
            <Table hoverable={true}>
                <Table.Head>
                    <Table.HeadCell>
                        Stagione
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Ambito
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Punteggio
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Motivazione
                    </Table.HeadCell>
                </Table.Head>
                <Table.Body>
                    {
                        (!user?.ratings || user?.scores.length < 1) && user?._id &&
                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 text-center">
                            <Table.Cell colSpan={5}>
                                Nessuna valutazione ancora inserita
                            </Table.Cell>
                        </Table.Row>
                    }
                    {user?.scores && user?.scores.length > 0 &&
                        user.scores.map((s, i) =>
                            <ScoresRow
                                key={s._id || `s_${i}`}
                                score={s}
                            />
                        )
                    }
                </Table.Body>
            </Table>
        </div>
    )
}

export default UserRatings;