function abbrText(string, charNumber = 20) {
    return string.length <= charNumber + 3
        ? string
        : `${string.slice(0, charNumber)}...`;
}

function genShortid() {
    let shortid = '';
    const str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 1; i <= 10; i++) {
        const char = Math.floor(Math.random() * str.length + 1);
        shortid += str.charAt(char);
    }

    return shortid;
}

function formatDate(isoString) {
    return new Date(isoString).toLocaleString('it-IT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        timeZone: 'Europe/Rome'
    });
}

function formatDateTime(isoString, weekday = false) {
    const options = weekday
        ? {
            weekday: 'long',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            timeZone: 'Europe/Rome'
        }
        : {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            timeZone: 'Europe/Rome'
        }
    return new Date(isoString).toLocaleString('it-IT', options);
}

function formToIsoString(dateString) {
    const [ymd, time] = dateString.split('T');
    const [hours, minutes] = time.split(':');
    const date = new Date(ymd);
    date.setHours(Number(hours));
    date.setMinutes(Number(minutes));
    return date.toISOString();
}

function isoToForm(isostring, type = 'date') {
    const date = new Date(isostring);
    const year = date.toLocaleString('it-IT', { year: 'numeric', timeZone: 'Europe/Rome' });
    const month = date.toLocaleString('it-IT', { month: '2-digit', timeZone: 'Europe/Rome' });
    const day = date.toLocaleString('it-IT', { day: '2-digit', timeZone: 'Europe/Rome' });
    const time = date.toLocaleString('it-IT', { timeStyle: 'short', timeZone: 'Europe/Rome' });
    return `${year}-${month}-${day}${type === 'datetime' ? `T${time}` : ''}`;
}

function newDate(endDay = false) {
    const date = new Date();
    date.setHours(endDay ? 23 : 0);
    date.setMinutes(endDay ? 59 : 0);
    date.setSeconds(endDay ? 59 : 0);
    return date.toISOString();
}

function sortByName(array, property) {
    let prop1 = '';
    let prop2 = '';
    if (property.includes('.')) {
        [prop1, prop2] = property.split('.');
    } else {
        prop1 = property;
    }
    return [...array].sort((a, b) => {
        const nameA = prop2 ? a[prop1][prop2].toUpperCase() : a[prop1].toUpperCase();
        const nameB = prop2 ? b[prop1][prop2].toUpperCase() : b[prop1].toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });
}

function sortByDate(array, property) {
    let prop1 = '';
    let prop2 = '';
    if (property.includes('.')) {
        [prop1, prop2] = property.split('.');
    } else {
        prop1 = property;
    }
    return [...array].sort((a, b) => {
        const dateA = prop2 ? new Date(a[prop1][prop2]) : new Date(a[prop1]);
        const dateB = prop2 ? new Date(b[prop1][prop2]) : new Date(b[prop1]);
        return dateB - dateA;
    })
}

function sortByValue(array, property) {
    let prop1 = '';
    let prop2 = '';
    if (property.includes('.')) {
        [prop1, prop2] = property.split('.');
    } else {
        prop1 = property;
    };
    return [...array].sort((a, b) => {
        const valueA = prop2 ? a[prop1][prop2] : a[prop1];
        const valueB = prop2 ? b[prop1][prop2] : b[prop1];
        return valueB - valueA;
    })
}

function getVote(report, role) {
    let complexities = {
        add_duration: 0,
        tech_complexity: 0,
        gest_complexity: 0,
        disc_complexity: 0,
        final: 0
    }
    let sections = {
        complexity: {
            table: [0, 0.5, 1, 1.5, 2],
            result: 0
        },
        aspect: {
            table: [-0.4, -0.2, 0],
            result: 0
        },
        delay: {
            table: [-1, -0.5, 0],
            result: 0
        },
        prot: {
            table: [-0.5, -0.3, 0],
            result: 0
        },
        whistle: {
            table: [-0.5, -0.3, 0],
            result: 0
        },
        complaint: {
            table: [-0.4, -0.2, 0],
            result: 0
        },
        technic: {
            table: [-1, -0.5, 0, 0.4],
            result: 0
        },
        errors_1st: {
            table: [-0.1, -0.25, -0.4],
            result: 0
        },
        errors_2nd: {
            table: [-0.1, -0.25, -0.4],
            result: 0
        },
        errors_3rd: {
            table: [-0.1, -0.25, -0.4],
            result: 0
        },
        collab: {
            table: [-1, -0.5, 0, 0.3],
            result: 0
        },
        gest: {
            table: [-1, -0.5, 0, 0.5],
            result: 0
        },
        conc: {
            table: [-1, -0.5, 0, 0.3],
            result: 0
        },
        disc: {
            table: [-1, -0.5, 0, 0.5],
            result: 0
        },
        delays: {
            table: [-0.5, -0.3, 0, 0.5],
            result: 0
        },
        interview: {
            table: [-0.2, -0.1, 0]
        }
    }

    // Calcolo Complessità
    const getComplexity = (sum) => {
        if (sum >= 6) return 2;
        if (sum >= 4.5 && sum < 6) return 1.5;
        if (sum >= 3 && sum < 4.5) return 1;
        if (sum >= 1.5 && sum < 3) return 0.5;
        return 0;
    }

    const complexity_table = [0, 0.5, 1.5, 2];
    complexities.add_duration = report.match.duration < 80 ? 0 :
        report.match.duration > 110 ? 1
            : 0.5;
    complexities.tech_complexity = complexity_table[report.technical.complexity];
    complexities.gest_complexity = complexity_table[report.relational.gest_difficulty];
    complexities.disc_complexity = complexity_table[report.discipline.gest_discipline];
    complexities.final = getComplexity(
        (
            complexities.add_duration * 10
            + complexities.tech_complexity * 10
            + complexities.gest_complexity * 10
            + complexities.disc_complexity * 10
        )
        / 10);

    // Calcolo degli errori

    const calcErrors = ({ std, sev }) => {
        const standard = std.reduce((prev, value) => value > 2 ? prev + 2 : prev + value, 0);
        const medium = std.reduce((prev, value) => value > 2 ? prev + value - 2 : prev, 0);
        const severe = sev.reduce((prev, value) => prev + value, 0);
        return {
            std: standard,
            med: medium,
            sev: severe
        }
    }

    const getResult = (index, table) => {
        return table[index];
    }

    const errorslist1ref = {
        std: [
            report.technical.ballsinout_ord_1ref,
            report.technical.balltouches_ord_1ref,
            report.technical.firsttouch_ord_1ref,
            report.technical.penetration_ord_1ref,
            report.technical.posfaults_ord_1ref,
            report.technical.nettouches_ord_1ref,
            report.technical.walltouches_ord_1ref,
            report.technical.airplay_ord_1ref,
            report.technical.defensefaults_ord_1ref,
            report.technical.servefaults_ord_1ref,
            report.technical.liberofaults_ord_1ref,
            report.technical.otherfaults_ord_1ref
        ],
        sev: [
            report.technical.ballsinout_sev_1ref,
            report.technical.balltouches_sev_1ref,
            report.technical.firsttouch_sev_1ref,
            report.technical.penetration_sev_1ref,
            report.technical.posfaults_sev_1ref,
            report.technical.nettouches_sev_1ref,
            report.technical.walltouches_sev_1ref,
            report.technical.airplay_sev_1ref,
            report.technical.defensefaults_sev_1ref,
            report.technical.servefaults_sev_1ref,
            report.technical.liberofaults_sev_1ref,
            report.technical.otherfaults_sev_1ref
        ]
    };

    const errorslist2ref = {
        std: [
            report.technical.ballsinout_ord_2ref,
            report.technical.penetration_ord_2ref,
            report.technical.posfaults_ord_2ref,
            report.technical.nettouches_ord_2ref,
            report.technical.walltouches_ord_2ref,
            report.technical.defensefaults_ord_2ref,
            report.technical.liberofaults_ord_2ref,
            report.technical.otherfaults_ord_2ref
        ],
        sev: [
            report.technical.ballsinout_sev_2ref,
            report.technical.penetration_sev_2ref,
            report.technical.posfaults_sev_2ref,
            report.technical.nettouches_sev_2ref,
            report.technical.walltouches_sev_2ref,
            report.technical.defensefaults_sev_2ref,
            report.technical.liberofaults_sev_2ref,
            report.technical.otherfaults_sev_2ref
        ]
    };

    const errors = role === '1st' ? calcErrors(errorslist1ref) : calcErrors(errorslist2ref);
    const results = {
        aspect: {
            ...sections.aspect,
            result: role === '1st' ? getResult(report.image.aspect1ref, sections.aspect.table) : getResult(report.image.aspect2ref, sections.aspect.table)
        },
        delay: {
            ...sections.delay,
            result: role === '1st' ? getResult(report.image.delay1ref, sections.delay.table) : getResult(report.image.delay2ref, sections.delay.table)
        },
        prot:
        {
            ...sections.prot,
            result: role === '1st' ? getResult(report.image.prot1ref, sections.prot.table) : getResult(report.image.prot2ref, sections.prot.table)
        },
        whistle: {
            ...sections.whistle,
            result: role === '1st' ? getResult(report.image.whistle1ref, sections.whistle.table) : getResult(report.image.whistle2ref, sections.whistle.table)
        },
        complaint: {
            ...sections.complaint,
            result: role === '1st' ? getResult(report.image.complaint1ref, sections.complaint.table) : getResult(report.image.complaint2ref, sections.complaint.table)
        },
        technic: {
            ...sections.technic,
            result: role === '1st' ? getResult(report.technical.tech1ref, sections.technic.table) : getResult(report.technical.tech2ref, sections.technic.table)
        },
        errors_1st: {
            ...sections.errors_1st,
            result: errors.std * (Number(sections.errors_1st.table[0]) * 100) / 100
        },
        errors_2nd: {
            ...sections.errors_2nd,
            result: errors.med * (Number(sections.errors_2nd.table[1]) * 100) / 100
        },
        errors_3rd: {
            ...sections.errors_3rd,
            result: errors.sev * (Number(sections.errors_3rd.table[2]) * 100) / 100
        },
        collab: {
            ...sections.collab,
            result: role === '1st' ? getResult(report.technical.collab1ref, sections.collab.table) : getResult(report.technical.collab2ref, sections.collab.table)
        },
        gest: {
            ...sections.gest,
            result: role === '1st' ? getResult(report.relational.gest1ref, sections.gest.table) : getResult(report.relational.gest2ref, sections.gest.table)
        },
        conc: {
            ...sections.conc,
            result: role === '1st' ? getResult(report.relational.conc1ref, sections.conc.table) : getResult(report.relational.conc2ref, sections.conc.table)
        },
        disc: {
            ...sections.disc,
            result: role === '1st' ? getResult(report.discipline.discipline, sections.disc.table) : getResult(report.discipline.disc_interation, sections.disc.table)
        },
        delays: {
            ...sections.delays,
            result: role === '1st' ? getResult(report.discipline.delays1ref, sections.delays.table) : getResult(report.discipline.delays2ref, sections.delays.table)
        },
        interview: {
            ...sections.interview,
            result: role === '1st' ? getResult(report.interview.interview1ref, sections.interview.table) : getResult(report.interview.interview2ref, sections.interview.table)
        }
    }
    const modifiers = Object.keys(results).reduce((prev, curr) => (prev * 100 + results[curr].result * 100) / 100, 0);
    const vote = 96 + complexities.final + modifiers || 0;
    
    return vote;
}

export default genShortid;
export {
    abbrText,
    formatDate,
    formatDateTime,
    formToIsoString,
    getVote,
    isoToForm,
    newDate,
    sortByDate,
    sortByName,
    sortByValue
};