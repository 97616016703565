import React from "react";

// Components
import { Button, Table } from "flowbite-react";

// Icons
import { EditIcon } from "../../../images/icons/Icons";

const UserRow = ({ setModalState, setUser, user, toggleUserModal }) => {
    const committees = {
        'ak': 'Akranis',
        'ct': 'Catania',
        'me': 'Messina',
        'mi': 'Monti Iblei',
        'pa': 'Palermo',
        'tp': 'Trapani'
    }

    const editUser = () => {
        setModalState('edit');
        setUser(user);
        toggleUserModal();
    }

    return (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                {user.lastname} {user.firstname}
            </Table.Cell>
            <Table.Cell className="hidden xl:table-cell">
                {user.email}
            </Table.Cell>
            <Table.Cell>
                {committees[user.committee]}
            </Table.Cell>
            <Table.Cell className="hidden md:table-cell">
                {user.authrole < 1 ? 'Amministratore' : 'Osservatore'}
            </Table.Cell>
            <Table.Cell>
                <Button
                    color="warning"
                    onClick={editUser}
                    size="xs"
                >
                    <EditIcon />
                </Button>
            </Table.Cell>
        </Table.Row>
    );
};

const UserList = ({ users, setModalState, setUser, toggleUserModal }) => {

    return (
        users.map(user =>
            <UserRow key={user._id} user={user} setModalState={setModalState} setUser={setUser} toggleUserModal={toggleUserModal} />
        )
    );
};

export default UserList;