import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    _id: '',
    username: '',
    firstname: '',
    lastname: '',
    email: '',
    authrole: 3
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        assign: (state, action) => action.payload,
        logOut: () => initialState
    }
});

export const { assign, logOut } = userSlice.actions;
export default userSlice.reducer;