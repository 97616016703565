import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authProvider as AUTH } from '../API/api';
import { logOut } from '../features/user/userSlice';
import fipavLogo from '../images/fipavsicilia.png'


const Navbar = ({ title }) => {
    const [hiddenState, setHiddenState] = useState(true);
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const toggleHiddenState = () => {
        const elementsToHide = document.querySelectorAll('.hide-element');
        setHiddenState(!hiddenState);
        elementsToHide.forEach(el => {
            hiddenState && el.classList.remove('hidden');
            !hiddenState && el.classList.add('hidden');
        });
    }

    const logout = () => {
        AUTH.signout();
        dispatch(logOut());
        navigate('/')
    }

    const adminMenu = (
        <>
            <Link to="/stagioni-sportive" className="md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0">
                <li className="rounded text-gray-200 hover:text-gray-900 bg-blue-500 hover:bg-blue-600 p-2">
                    Stagioni
                </li>
            </Link>
            <Link to="/accounts" className="md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0">
                <li className="rounded text-gray-200 hover:text-gray-900 bg-blue-500 hover:bg-blue-600 p-2">
                    Accounts
                </li>
            </Link>
            <Link to="/arbitri" className="md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0">
                <li className="rounded text-gray-200 hover:text-gray-900 bg-blue-500 hover:bg-blue-600 p-2">
                    Arbitri
                </li>
            </Link>
            <Link to="/valutazioni" className="md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0">
                <li className="rounded text-gray-200 hover:text-gray-900 bg-blue-500 hover:bg-blue-600 p-2">
                    Ins. Valutazioni
                </li>
            </Link>
        </>
    );

    const userMenu = (
        <>
            {user.authrole > 1 && user._id &&
                <Link to="/home" className="md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0">
                    <li className="rounded bg-blue-500 hover:bg-blue-600 text-gray-200 hover:text-gray-900 p-2">
                        Home
                    </li>
                </Link>
            }
            {user.authrole < 2 &&
                <Link to="/report" className="md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0">
                    <li className="rounded bg-blue-500 hover:bg-blue-600 text-gray-200 hover:text-gray-900 p-2">
                        Report
                    </li>
                </Link>
            }
            {user._id &&
                <Link to="/valutazioni-arbitri" className="md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0">
                    <li className="rounded bg-blue-500 hover:bg-blue-600 text-gray-200 hover:text-gray-900 p-2">
                        Punteggi
                    </li>
                </Link>
            }
            {user._id &&
                <Link to="/profilo" className="md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0">
                    <li className="rounded bg-blue-500 hover:bg-blue-600 text-gray-200 hover:text-gray-900 p-2" title="Profilo">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 hidden md:inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                        </svg>
                        <span className="md:hidden">Profilo</span>
                    </li>
                </Link>
            }
            {user._id &&
                <button
                    className="md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0"
                    onClick={logout}
                >
                    <li className="rounded bg-blue-500 hover:bg-blue-600 text-gray-200 hover:text-gray-900 p-2" title="Logout">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 hidden md:inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                        </svg>
                        <span className="md:hidden">Logout</span>
                    </li>
                </button>
            }
        </>
    )

    return (
        <>
            { /* Navbar */}
            <nav className="bg-gradient-to-r from-blue-300 to-blue-600 border-gray-200 px-2">
                <div className="container mx-auto flex flex-wrap items-center justify-between">
                    <Link to="/" className="flex">
                        <img src={fipavLogo} className="h-14" alt="logo" />
                        <span className="ml-4 self-center text-md sm:text-xl text-gray-200 font-semibold whitespace-nowrap tracking-wide sm:tracking-wider uppercase">{title}</span>
                    </Link>
                    {user._id &&
                        <button data-collapse-toggle="mobile-menu" type="button" className="md:hidden ml-3 text-gray-700 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-300 rounded-lg inline-flex items-center justify-center" aria-controls="mobile-menu-2" aria-expanded="false" onClick={toggleHiddenState}>
                            <span className="sr-only">Open main menu</span>
                            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                            <svg className="hidden w-6 h-6 hide-element" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    }
                    <div className="hidden md:block w-full md:w-auto text-center hide-element" id="mobile-menu">
                        <ul className="flex-col md:flex-row flex md:space-x-4 mt-4 md:mt-0 md:text-sm md:font-medium">
                            {user.authrole < 1 && adminMenu}
                            {userMenu}
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar;
