import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { assign as assignSeason } from './features/season/seasonSlice';
import { assign as assignUser } from './features/user/userSlice';
import { authProvider as AUTH } from './API/api';
import { serviceProvider as API } from './API/api';

// Components
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import OrangeBar from './components/OrangeBar';


function App() {
  const dispatch = useDispatch();
  const { _id: userId } = useSelector(state => state.user);
  const [title, setTitle] = useState('Portale Osservazioni')

  // Se l'utente è verificato, va memorizzato
  useEffect(() => {
    if (!userId) {
      AUTH.verifyLogin()
        .then(response => {
          response && dispatch(assignUser(response.user));
        })
        .catch(err => console.error(err));
    }
  }, [dispatch, userId]);

  // Verifico l'attuale stagione e la memorizzo
  useEffect(() => {
    API.get(`seasons?date=${new Date().toISOString()}`)
      .then(res => {
        if (res.success) {
          dispatch(assignSeason(res.seasons[0]));
        }
      })
  }, [dispatch]);

  return (
    <div className='bg-gradient-to-r from-indigo-100 to-indigo-200'>
      <Navbar title={title} />
      <OrangeBar />
      <div className="min-h-screen mt-5 mx-5 py-4 pb-16 md:py-12 lg:py-20 px-4 lg:px-24 xl:px-48 flex flex-col gap-4">
        <Outlet context={[setTitle]} />
      </div>
      <Footer />
    </div>
  );
}

export default App;
