import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { sortByName } from "../../lib/genericUtils";

// Api
import { serviceProvider as API } from "../../API/api";

// Components
import { Table } from "flowbite-react";
import LoadingSpinner from "../LoadingSpinner";
import RefList from "./referees/RefList";
import RefModal from "./referees/RefModal";

const Referees = () => {
    const [setTitle] = useOutletContext();
    const [loading, setLoading] = useState(true);
    const [referees, setReferees] = useState([]);
    const [modalState, setModalState] = useState('new');
    const [newUserModal, setNewUserModal] = useState(true);
    const [referee, setReferee] = useState({
        password: '',
        firstname: '',
        lastname: '',
        email: '',
        committee: 'ak',
        authrole: 2
    });

    useEffect(() => {
        API.get('users?authrole=2', true)
            .then(res => {
                res.success && setReferees(sortByName(res.users, 'lastname'));
                setLoading(false);
            })
            .catch(err => console.error(err));
    }, []);

    useEffect(() => {
        setTitle('Gestione Arbitri')
    }, [setTitle])

    function toggleUserModal() {
        setNewUserModal(!newUserModal);
        if (!newUserModal) {
            document.querySelector('[modal-backdrop]').remove();
        } else {
            const backdropEl = document.createElement('div');
            backdropEl.setAttribute('modal-backdrop', '');
            backdropEl.classList.add('bg-gray-900', 'bg-opacity-50', 'fixed', 'inset-0', 'z-40');
            document.querySelector('body').append(backdropEl);
        }
    }

    function newUser() {
        setModalState('new');
        setReferee({
            password: '',
            firstname: '',
            lastname: '',
            email: '',
            committee: 'ak',
            role: 'reg',
            authrole: 2
        });
        toggleUserModal();
    }

    return (
        <div className="flex flex-col gap-2">
            <Table hoverable={true}>
                <Table.Head>
                    <Table.HeadCell>
                        Nome
                    </Table.HeadCell>
                    <Table.HeadCell className="hidden md:table-cell">
                        E-Mail
                    </Table.HeadCell>
                    <Table.HeadCell className="hidden sm:table-cell">
                        Comitato
                    </Table.HeadCell>
                    <Table.HeadCell>
                        <span className="sr-only">Edit</span>
                    </Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                    {loading && <LoadingSpinner colSpan={4} />}
                    {!loading && !referees.length &&
                        (
                            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white" colSpan="4">
                                    Nessun arbitro ancora inserito
                                </Table.Cell>
                            </Table.Row>

                        )
                    }
                    <RefList
                        loading={loading}
                        referees={referees}
                        setModalState={setModalState}
                        setReferee={setReferee}
                        toggleUserModal={toggleUserModal}
                    />
                </Table.Body>
            </Table>
            <div>
                <button
                    type="button"
                    className="btn-default dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    onClick={newUser}
                >
                    Nuovo Arbitro
                </button>
            </div>
            <RefModal
                newUserModal={newUserModal}
                toggleUserModal={toggleUserModal}
                modalState={modalState}
                referee={referee}
                setReferee={setReferee}
                setReferees={setReferees}
            />
        </div>
    );
}

export default Referees;