import React from 'react';
import { useSelector } from 'react-redux';
import { serviceProvider as API } from '../../../API/api';
import { abbrText, formatDateTime } from '../../../lib/genericUtils';

// Components
import { Button, Table, Tooltip } from 'flowbite-react';

function ReportRow({ showAllReports, report, setAction, setButton, setCompleteReport, setReport, setReports, toggleModal }) {
    const user = useSelector(state => state.user);

    const editReport = e => {
        e.preventDefault();
        // Per modificare il report, tolgo i campi popolati
        setReport(() => ({
            ...report,
            general: {
                ...report.general,
                author: report.general.author._id,
                first_ref: report.general.first_ref._id,
                second_ref: report.general.second_ref?._id || undefined
            }
        }));
        setAction('edit');
        setButton('edit');
    }

    const deleteReport = e => {
        e.preventDefault();
        setReport(() => ({
            ...report,
            general: {
                ...report.general,
                author: report.general.author._id,
                first_ref: report.general.first_ref._id,
                second_ref: report.general.second_ref?._id || undefined
            }
        }));
        setAction('delete');
        toggleModal(e);
    }

    const showPreview = () => {
        setCompleteReport(report);
        setButton('preview');
    }

    const handleValidation = e => {
        e.preventDefault();
        const valid = { valid: !report.valid }
        API.update(`reports/${report._id}`, JSON.stringify(valid), true)
            .then(res => {
                if (res?.success) {
                    setReports(prevState => {
                        return [...prevState.filter(report => report._id !== res.report._id), res.report].sort((a, b) => new Date(a.general.date) - new Date(b.general.date))
                    });
                }
            })
            .catch(err => console.error(err));
    }

    return (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className='md:whitespace-nowrap md:font-medium md:text-gray-900 md:dark:text-white'>
                {formatDateTime(report.general.date)}
            </Table.Cell>
            <Table.Cell className={user.authrole < 1 ? 'hidden md:table-cell' : 'hidden'}>
                <Tooltip content={`Ultimo aggiornamento il ${formatDateTime(report.updated_at)}`}>
                    {formatDateTime(report.inserted_at)}
                </Tooltip>
            </Table.Cell>
            <Table.Cell>
                <span className="hidden md:block">{report.general.teams}</span>
                <span className="md:hidden">{abbrText(report.general.teams, 25)}</span>
            </Table.Cell>
            <Table.Cell>
                <span className="hidden md:block">
                    {`${report.general.first_ref?.lastname} ${report.general.first_ref?.firstname[0]}.`}
                    {report.general.second_ref && ` - ${report.general.second_ref.lastname} ${report.general.second_ref.firstname[0]}.`}
                </span>
                <span className="md:hidden">
                    {
                        report.general.second_ref?.lastname
                            ? abbrText(`${report.general.first_ref?.lastname} ${report.general.first_ref?.firstname[0]}. - ${report.general.second_ref.lastname} ${report.general.second_ref.firstname[0]}.`, 20)
                            : abbrText(`${report.general.first_ref?.lastname} ${report.general.first_ref?.firstname[0]}.`, 20)
                    }
                </span>
            </Table.Cell>
            {
                showAllReports &&
                <Table.Cell className="hidden lg:table-cell">
                    {report.general.author?.lastname} {report.general.author?.firstname[0]}.
                </Table.Cell>
            }
            <Table.Cell className="flex flex-col md:flex-row gap-1">
                {!report.valid &&
                    <Tooltip content="Modifica report">
                        <Button
                            color="warning"
                            size="xs"
                            onClick={editReport}
                            disabled={report.valid}
                        >
                            <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                >
                                </path>
                            </svg>
                        </Button>
                    </Tooltip>}
                <Tooltip content="Mostra il report">
                    <Button
                        size="xs"
                        onClick={showPreview}
                    >
                        <svg
                            className="w-6 h-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                            >
                            </path>
                        </svg>
                    </Button>
                </Tooltip>
                {!report.valid &&
                    <Tooltip content="Elimina report">
                        <Button
                            color="failure"
                            size="xs"
                            title="Elimina"
                            onClick={deleteReport}
                            disabled={report.valid}
                        >
                            <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                >
                                </path>
                            </svg>
                        </Button>
                    </Tooltip>}
                {
                    user.authrole < 1 && (
                        <Tooltip content={report.valid ? 'Annulla omologazione' : 'Omologa il report'}>
                            <Button
                                color={report.valid ? 'success' : 'warning'}
                                size="xs"
                                onClick={handleValidation}
                            >
                                <svg
                                    className="w-6 h-6"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                                    >
                                    </path>
                                </svg>
                            </Button>
                        </Tooltip>
                    )
                }
            </Table.Cell>
        </Table.Row>
    )
}

function ReportTable({ showAllReports, reports, setAction, setButton, setCompleteReport, setReport, setReports, toggleModal }) {
    const user = useSelector(state => state.user);

    return (
        <Table hoverable={true}>
            <Table.Head>
                <Table.HeadCell>
                    Data Gara
                </Table.HeadCell>
                <Table.HeadCell className={user.authrole < 1 ? 'hidden md:table-cell' : 'hidden'}>
                    Data inserimento
                </Table.HeadCell>
                <Table.HeadCell>
                    Squadre
                </Table.HeadCell>
                <Table.HeadCell>
                    Arbitri
                </Table.HeadCell>
                {
                    showAllReports && (
                        <Table.HeadCell className='hidden lg:table-cell'>
                            Osservatore
                        </Table.HeadCell>
                    )
                }
                <Table.HeadCell>
                    <span className="sr-only">Edit</span>
                </Table.HeadCell>
            </Table.Head>
            <Table.Body className='divide-y'>
                {
                    !reports.length && (
                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                            <Table.Cell
                                className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center"
                                colSpan={showAllReports ? 6 : 5}
                            >
                                Nessun report da visualizzare
                            </Table.Cell>
                        </Table.Row>
                    )
                }
                {
                    reports.map((report, index) => (
                        <ReportRow
                            key={index}
                            showAllReports={showAllReports}
                            report={report}
                            setAction={setAction}
                            setButton={setButton}
                            setCompleteReport={setCompleteReport}
                            setReport={setReport}
                            setReports={setReports}
                            toggleModal={toggleModal}
                        />
                    ))
                }
            </Table.Body>
        </Table>
    )
}

export default ReportTable;