import { Spinner } from "flowbite-react";

function LoadingSpinner({ colSpan }) {
    return (
        <tr className="text-center bg-white border-b dark:bg-gray-800 dark:border-gray-700">
            <td colSpan={colSpan}>
                <Spinner />
            </td>
        </tr>
    )
}

export default LoadingSpinner;