import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

// Api
import { serviceProvider as API } from "../../API/api";

// Components
import { Button, Table } from "flowbite-react";
import LoadingSpinner from "../LoadingSpinner";
import UserList from "./accounts/UserList";
import UserModal from "./accounts/UserModal";

const Accounts = () => {
    const [setTitle] = useOutletContext();
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [modalState, setModalState] = useState('new');
    const [newUserModal, setNewUserModal] = useState(true);
    const [user, setUser] = useState({
        password: '',
        firstname: '',
        lastname: '',
        email: '',
        committee: 'ak',
        role: 'reg',
        authrole: 1
    });

    useEffect(() => {
        API.get('users?authrole=0&authrole=1', true)
            .then(res => {
                res.success && setUsers(res.users);
                setLoading(false);
            })
            .catch(err => console.error(err));
    }, []);

    useEffect(() => {
        setTitle('Gestione Accounts')
    }, [setTitle])

    function toggleUserModal() {
        setNewUserModal(!newUserModal);
        if (!newUserModal) {
            document.querySelector('[modal-backdrop]').remove();
        } else {
            const backdropEl = document.createElement('div');
            backdropEl.setAttribute('modal-backdrop', '');
            backdropEl.classList.add('bg-gray-900', 'bg-opacity-50', 'fixed', 'inset-0', 'z-40');
            document.querySelector('body').append(backdropEl);
        }
    }

    function newUser() {
        setModalState('new');
        setUser({
            password: '',
            firstname: '',
            lastname: '',
            email: '',
            committee: 'ak',
            role: 'reg',
            authrole: 1
        });
        toggleUserModal();
    }

    return (
        <div className="flex flex-col gap-2">
            <Table hoverable={true}>
                <Table.Head>
                    <Table.HeadCell>
                        Nome
                    </Table.HeadCell>
                    <Table.HeadCell className="hidden xl:table-cell">
                        E-Mail
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Comitato
                    </Table.HeadCell>
                    <Table.HeadCell className="hidden md:table-cell">
                        Accesso
                    </Table.HeadCell>
                    <Table.HeadCell>
                        <span className="sr-only">Edit</span>
                    </Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                    {loading && <LoadingSpinner colSpan={5} />}
                    {!loading && !users.length &&
                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white" colSpan={5}>
                                Nessun utente ancora inserito
                            </Table.Cell>
                        </Table.Row>
                    }
                    <UserList
                        users={users}
                        setModalState={setModalState}
                        setUser={setUser}
                        toggleUserModal={toggleUserModal}
                    />
                </Table.Body>
            </Table>
            <div>
                <Button
                    size="sm"
                    onClick={newUser}
                >
                    Nuovo Utente
                </Button>
            </div>
            <UserModal
                newUserModal={newUserModal}
                toggleUserModal={toggleUserModal}
                modalState={modalState}
                user={user}
                setUser={setUser}
                setUsers={setUsers}
            />
        </div>
    );
}

export default Accounts;