import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    _id: '',
    name: '',
    startDate: '',
    endDate: ''
}

const seasonSlice = createSlice({
    name: 'season',
    initialState,
    reducers: {
        assign: (state, action) => action.payload
    }
});

export const { assign } = seasonSlice.actions;
export default seasonSlice.reducer;