import { useSelector } from 'react-redux';

const RequireAuth = ({ children, role }) => {
    const user = useSelector(state => state.user);

    if (user.authrole <= role)
        return children
}

const RequireUser = ({ children, role }) => {
    const user = useSelector(state => state.user);

    if (user.authrole === role)
        return children
}

export { RequireAuth, RequireUser };