import React from "react";

// Components
import { Table, Tooltip } from "flowbite-react";


function ScoresRow({ score, setAction, setScore, setOpenModal }) {

    return (
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell>
                <Tooltip
                    content={`Ins. il ${new Date(score.inserted_at).toLocaleDateString('it-IT', {hour: '2-digit', minute: '2-digit'})} - Ult. mod. il ${new Date(score.updated_at).toLocaleDateString('it-IT', {hour: '2-digit', minute: '2-digit'})}`}
                >
                {score.season?.name}
                </Tooltip>
            </Table.Cell>
            <Table.Cell>
                {score.context}
            </Table.Cell>
            <Table.Cell>
                {score.points}
            </Table.Cell>
            <Table.Cell>
                {score.reason}
            </Table.Cell>
        </Table.Row>
    )
}

export default ScoresRow;