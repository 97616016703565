import React, { useState } from "react";
import { serviceProvider as API } from "../../../API/api";

// Components
import { Alert, Button, Modal, Label, Select, TextInput } from "flowbite-react";

// Icons
import { InfoIcon } from "../../../images/icons/Icons";
import { sortByName } from "../../../lib/genericUtils";


function RatingsModal({ action, openModal, rating, referee, seasons, setRatingAlert, setOpenModal, setRating, setReferee, setReferees }) {
    const [alert, setAlert] = useState({ type: '', label: '' });

    const handleInput = e => {
        const { id, value } = e.currentTarget;
        setRating(prevState => ({
            ...prevState,
            [id]: value
        }))
    }

    const handleSeason = e => {
        const { value } = e.currentTarget;
        setRating(prevState => (
            {
                ...prevState,
                season: seasons.find(s => s._id.toString() === value) || { _id: '' }
            }
        ))
    }

    const handleSubmit = e => {
        e.preventDefault();
        switch (action) {
            case 'delete':
                const deleteRtg = {
                    ...referee,
                    ratings: [
                        ...referee.ratings.filter(r => r._id.toString() !== rating._id.toString())
                    ]
                }
                API.update(`users/${referee._id}`, JSON.stringify(deleteRtg), true)
                    .then(res => {
                        if (res.success) {
                            setReferee(deleteRtg);
                            setReferees(prevState => sortByName([
                                ...prevState.filter(r => r._id.toString() !== referee._id.toString()),
                                deleteRtg
                            ], 'lastname'));
                            setRatingAlert({ type: 'success', label: 'Valutazione eliminata' });
                            setOpenModal(false);
                        } else {
                            setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto, riprova più tardi' });
                        }
                    })
                break;
            case 'edit':
                const editUser = {
                    ...referee,
                    ratings: [
                        ...referee.ratings.filter(r => r._id.toString() !== rating._id.toString()),
                        rating
                    ]
                }
                API.update(`users/${referee._id}`, JSON.stringify(editUser), true)
                    .then(res => {
                        if (res.success) {
                            setReferee(editUser);
                            setReferees(prevState => sortByName([
                                ...prevState.filter(r => r._id.toString() !== referee._id.toString()),
                                editUser
                            ], 'lastname'));
                            setRatingAlert({ type: 'success', label: 'Valutazione modificata' });
                            setOpenModal(false);
                        } else {
                            setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto, riprova più tardi' });
                        }
                    })
                break;
            default:
                const updUser = {
                    ...referee,
                    ratings: [
                        ...referee.ratings,
                        rating
                    ]
                };
                API.update(`users/${referee._id}`, JSON.stringify(updUser), true)
                    .then(res => {
                        if (res.success) {
                            setReferee(res.user);
                            setReferees(prevState => sortByName([
                                ...prevState.filter(r => r._id.toString() !== referee._id.toString()),
                                res.user
                            ], 'lastname'));
                            setRatingAlert({ type: 'success', label: 'Punteggio storico inserito' });
                            setOpenModal(false);
                        } else {
                            setAlert({ type: 'failure', label: res.msg || 'Qualcosa è andato storto, riprova più tardi' });
                        }
                    })
                    .catch(err => {
                        console.error(err);
                        setAlert({ type: 'failure', label: 'Qualcosa è andato storto, si prega di riprovare' });
                    });
        }
    }

    return (
        <Modal
            show={openModal}
            size='lg'
            popup={true}
            onClose={() => setOpenModal(false)}
        >
            <Modal.Header>
                <div className="ml-4 my-2">
                    {action === 'new' ? 'Nuova' : action === 'edit' ? 'Modifica' : 'Elimina'} Punteggio
                </div>
            </Modal.Header>
            <Modal.Body>
                {alert.type &&
                    <Alert
                        color={alert.type}
                        icon={InfoIcon}
                    >
                        <span>
                            <span className="font-medium">
                                Attenzione!
                            </span>
                            {' '}
                            {alert.label}
                        </span>
                    </Alert>
                }
                <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
                    <form
                        className="flex flex-col gap-4"
                        onSubmit={handleSubmit}
                    >
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="season"
                                    value="Stagione"
                                />
                            </div>
                            <Select
                                id="season"
                                value={rating.season?._id}
                                onChange={handleSeason}
                                disabled={action === 'delete' ? true : false}
                            >
                                <option value=''>
                                    -
                                </option>
                                {seasons.map(s =>
                                    <option key={s._id} value={s._id}>
                                        {s.name}
                                    </option>
                                )}
                            </Select>
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="techPts"
                                    value="Punteggio Tecnico"
                                />
                            </div>
                            <TextInput
                                id="techPts"
                                type="text"
                                required={true}
                                value={rating.techPts}
                                onChange={handleInput}
                                disabled={action === 'delete' ? true : false}
                            />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="discPts"
                                    value="Punteggio Comportamentale"
                                />
                            </div>
                            <TextInput
                                id="discPts"
                                type="text"
                                required={true}
                                value={rating.discPts}
                                onChange={handleInput}
                                disabled={action === 'delete' ? true : false}
                            />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="commPts"
                                    value="Punteggio Commissione Regionale"
                                />
                            </div>
                            <TextInput
                                id="commPts"
                                type="text"
                                required={true}
                                value={rating.commPts}
                                onChange={handleInput}
                                disabled={action === 'delete' ? true : false}
                            />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="totalPts"
                                    value="Punteggio Complessivo"
                                />
                            </div>
                            <TextInput
                                id="totalPts"
                                type="text"
                                required={true}
                                value={rating.totalPts}
                                onChange={handleInput}
                                disabled={action === 'delete' ? true : false}
                            />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="judgement"
                                    value="Valutazione"
                                />
                            </div>
                            <Select
                                id="judgement"
                                value={rating.judgement}
                                onChange={handleInput}
                                disabled={action === 'delete' ? true : false}
                            >
                                <option>
                                    -
                                </option>
                                <option value="Eccellente">
                                    Eccellente
                                </option>
                                <option value="Ottimo">
                                    Ottimo
                                </option>
                                <option value="Buono">
                                    Buono
                                </option>
                                <option value="Sufficiente">
                                    Sufficiente
                                </option>
                                <option value="Insufficiente">
                                    Insufficiente
                                </option>
                                <option value="Non Valutabile">
                                    Non Valutabile
                                </option>
                            </Select>
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="group"
                                    value="Fascia di appartenenza"
                                />
                            </div>
                            <Select
                                id="group"
                                value={rating.group}
                                onChange={handleInput}
                                disabled={action === 'delete' ? true : false}
                            >
                                <option>-</option>
                                <option value="A">
                                    A
                                </option>
                                <option value="B">
                                    B
                                </option>
                                <option value="C">
                                    C
                                </option>
                                <option value="Master 1° Gruppo">
                                    Master 1° Gruppo
                                </option>
                                <option value="Master 2° Gruppo">
                                    Master 2° Gruppo
                                </option>
                                <option value="Master 3° Gruppo">
                                    Master 3° Gruppo
                                </option>
                                <option value="N">
                                    N
                                </option>
                            </Select>
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="position"
                                    value="Posizione"
                                />
                            </div>
                            <TextInput
                                id="position"
                                type="number"
                                required={true}
                                min={1}
                                step={1}
                                value={rating.position}
                                onChange={handleInput}
                                disabled={action === 'delete' ? true : false}
                            />
                        </div>
                        {action === 'delete' &&
                            <Alert color="warning">
                                Vuoi eliminare questo punteggio?
                            </Alert>
                        }
                        {action !== 'new' &&
                            <Button
                                color={action === 'delete' ? "failure" : "warning"}
                                type="submit">
                                {action === 'delete' ? "Elimina" : "Modifica"}
                            </Button>
                        }
                        {action === 'new' &&
                            <Button
                                type="submit"
                            >
                                Invia
                            </Button>
                        }
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default RatingsModal;